import { HttpClient } from '@angular/common/http';
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, NavController } from '@ionic/angular';
import { DocumentiService } from '../services/documenti.service';
import { DocumentifeService } from '../services/documentife.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-documentiedit',
  templateUrl: './documentiedit.page.html',
  styleUrls: ['./documentiedit.page.scss'],
})
export class DocumentieditPage implements OnInit  {

  public IDDocumento;
  public Documento:any =[];
  public DocumentiRighe: any = [];
  public myDownloadLink: string = "";
  
  constructor(public httpClient: HttpClient,  private route: ActivatedRoute, private router: Router, private _mysrv: DocumentiService, private _mysrvFE: DocumentifeService, private navCtrl: NavController, public _myglobal: GlobalService, public loadingController: LoadingController) {
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    //if (this._myglobal.IDLicenzaAnagrafica==null || this._myglobal.IDLicenzaAnagrafica<=0)
    {
      this.navCtrl.navigateRoot('/login');
    }
    this.route.paramMap.subscribe(params => {
      this.IDDocumento = params.get('iddocumento');
   });
  }

  
  _loadDocumento(){
    this._mysrv.loadDocumento(this.IDDocumento).subscribe((data : any) => {
      this.Documento = data[0];
    }, error => {
      console.log(error);
    });
 }

  _lodDocumentiRighe(){
    this._mysrv.loadDocumentiRighe(this.IDDocumento).subscribe((data : any) => {
      this.DocumentiRighe = data;
      console.log("DOCUMENTI RIGHE");
      console.log(this.DocumentiRighe);
    }, error => {
      console.log(error);
    });
 }

 
 ngOnInit() {
  this._loadDocumento();
  this._lodDocumentiRighe();
}

doRefresh() {
  this._loadDocumento();
  this._lodDocumentiRighe();
}

ionViewDidEnter(){
  this._myglobal.GETUserData();
}

// navback(){
//     this.navCtrl.navigateRoot('/tabs/tab3');
// }



async downloadXML(nomedoc) {
  const loading = await this.loadingController.create({
    message: 'Carcamento Documenti...',
  });
  await loading.present();
  this._mysrvFE.Download_Documento(nomedoc).subscribe((b64 : any) => {
    console.log(b64);
    console.log(nomedoc);
    this._mysrvFE.StoreFile(nomedoc+'.xml', b64, true).subscribe((data : any) => {
      console.log(data);
      this.myDownloadLink = data[0].Result;  
      loading.dismiss();  
         
      this._mysrvFE.InsertFEDownloaded(nomedoc).subscribe((data : any) => { 
      });
          
      console.log(this.myDownloadLink);    
      var anchor = document.createElement('a');
      anchor.href = this.myDownloadLink;
      anchor.target = '_blank';
      anchor.download = nomedoc+'.zip' ;
      anchor.click();
    }, error => {
      console.log(error);
      loading.dismiss();
    });
  }, error => {
    console.log(error);
    loading.dismiss();
  });
}


  
async downloadHTML(nomedoc) {
  const loading = await this.loadingController.create({
    message: 'Apertura Documento...',
  });
  await loading.present();
  this._mysrvFE.Download_Documento(nomedoc).subscribe((b64 : any) => {
    console.log(b64);
    console.log(nomedoc);
    this._mysrvFE.StoreFileHTML(nomedoc+'.xml', b64, false).subscribe((data : any) => {
      console.log(data);
      this.myDownloadLink = data[0].Result;  
      loading.dismiss();  
      console.log(this.myDownloadLink); 
      window.open(data[0].Result, '_blank')
     
    }, error => {
      console.log(error);
      loading.dismiss();
    });
  }, error => {
    console.log(error);
    loading.dismiss();
  });
}

}
