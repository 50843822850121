import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

 
  //public myURL: string = "http://10.0.0.4:85/";             //RINALDI
  //public myURL: string = "http://10.0.0.4:83/";             //RINALDI DEMO
  //public myURL: string = "http://demo.apiapp.redim.it/";    // DEMO NEXT
  //public myURL: string = "https://localhost:44360/";        // LOCAL TEST
  public myURL: string = "https://apiapp.redim.it/";         // NEXT APP
  //public myURL: string = "https://api.app.futuretouch.it/";         // NEXT APP
  

  public myURL_FE: string = "https://fe.redim.it/api/fe/";

  public IDLicenzaAnagrafica: any;
  public myToken: string = "";
  public PIVA: string = "";
  public CodiceUnita: string = "";
  public UserType: string = "";
  public ClientKey: string = "";
  public IDUtenteMaster: any;
  public UserData: any = [];
  public Aziende: any = [];
  public DBMode: string = "";
  public RendicontazioneMode: string = "range";
  public isEdit: boolean = false;


  public endpointLOCAL: string="";
  public endpointCLOUD: string="";

  GetIDLicenzaAnagraficaSession() {
    try {

      if (localStorage.getItem("IDLicenzaAnagrafica") != null && parseInt(localStorage.getItem("IDLicenzaAnagrafica")) > 0) {
        
        return true;
      }
    } catch (error) {
    }
    return false;
  }

  GETLocal() {
    try {

      if (localStorage.getItem("cnMode") != null && localStorage.getItem("cnMode") == "LOCAL") {
        return "NEXT_";
      }
    } catch (error) {
    }
    return "";
  }

  GETcnMode() {
    try {

      if (localStorage.getItem("cnMode") != null && localStorage.getItem("cnMode") != undefined) {
        return localStorage.getItem("cnMode");
      }
    } catch (error) {
    }
    return "";
  }

  GETUsername() {
    try {

      if (localStorage.getItem("Username") != null && localStorage.getItem("Username") != undefined) {
        return localStorage.getItem("Username");
      }
    } catch (error) {
    }
    return "";
  }

  GETUserType() {
    try {

      if (localStorage.getItem("UserType") != null && localStorage.getItem("UserType") != "") {
        this.UserType = localStorage.getItem("UserType");
        return localStorage.getItem("UserType");
      }
    } catch (error) {
    }
    return "";
  }

  GETIDDipendente() {
    try {
      if (localStorage.getItem("IDDipendente") != null && localStorage.getItem("IDDipendente") != "") {
        return localStorage.getItem("IDDipendente")
      }
    } catch (error) {
    }
    return 0;
  }

  GETIDUtente() {
    try {
      if (localStorage.getItem("IDUtenteMaster") != null && localStorage.getItem("IDUtenteMaster") != "") {
        return localStorage.getItem("IDUtenteMaster")
      }
    } catch (error) {
    }
    return 0;
  }

  GETIDLicenzaAnagrafica() {
    try {
      if (localStorage.getItem("IDLicenzaAnagrafica") != null && localStorage.getItem("IDLicenzaAnagrafica") != "") {
        this.IDLicenzaAnagrafica = localStorage.getItem("IDLicenzaAnagrafica");
        return localStorage.getItem("IDLicenzaAnagrafica")
      }
    } catch (error) {
    }
    return 0;
  }

  GETCodiceUnita() {
    try {
      if (localStorage.getItem("CodiceUnita") != null && localStorage.getItem("CodiceUnita") != "") {
        this.CodiceUnita = localStorage.getItem("CodiceUnita");
        return localStorage.getItem("CodiceUnita")
      }
    } catch (error) {
    }
    return "";
  }

  GETPIVA() {
    try {
      if (localStorage.getItem("PIVA") != null && localStorage.getItem("PIVA") != "") {
        this.PIVA=localStorage.getItem("PIVA");
        return localStorage.getItem("PIVA")
      }
    } catch (error) {
    }
    return "";
  }

  GETResponsabileRendicontazione() {
    try {
      if (localStorage.getItem("ResponsabileRendicontazione") != null && localStorage.getItem("ResponsabileRendicontazione") != undefined) {
        return localStorage.getItem("ResponsabileRendicontazione")
      }
    } catch (error) {
    }
    return false;
  }

  GETResponsabileMagazzini() {
    try {
      if (localStorage.getItem("ResponsabileMagazzini") != null && localStorage.getItem("ResponsabileMagazzini") != undefined) {
        return localStorage.getItem("ResponsabileMagazzini")
      }
    } catch (error) {
    }
    return false;
  }

  GETendpointCLOUD() {
    try {
      if (localStorage.getItem("endpointCLOUD") != null && localStorage.getItem("endpointCLOUD") != "") {
        return localStorage.getItem("endpointCLOUD")
      }
    } catch (error) {
    }
    return "";
  }


  GETendpointLOCAL() {
    try {
      if (localStorage.getItem("endpointLOCAL") != null && localStorage.getItem("endpointLOCAL") != "") {
        return localStorage.getItem("endpointLOCAL")
      }
    } catch (error) {
    }
    return "";
  }

  GETendpointCURRENT() {
    try {
      if (localStorage.getItem("endpointCURRENT") != null && localStorage.getItem("endpointCURRENT") != "") {
        this.myURL=localStorage.getItem("endpointCURRENT");
        return localStorage.getItem("endpointCURRENT")
      }
    } catch (error) {
    }
    return "";
  }

  GETUserData() {
    try {
      if (localStorage.getItem("UserData") != null && localStorage.getItem("UserData") != "") {
        this.UserData=JSON.parse(localStorage.getItem("UserData"));

        this.IDLicenzaAnagrafica = this.UserData.IDLicenzaAnagrafica;
        this.myToken = this.UserData.Token;
        this.PIVA = this.UserData.PIVA;
        this.CodiceUnita = this.UserData.CodiceUnita;
        this.UserType = this.UserData.UserType;
        this.ClientKey = this.UserData.ClientKey;
        this.IDUtenteMaster = this.UserData.IDUtenteMaster;
        this.GETRendicontazioneMode();
        console.log('RELOAD USER DATA : ' + localStorage.getItem("UserData"));
        return localStorage.getItem("UserData")
      }
    } catch (error) {
    }
    return "";
  }

  GETRendicontazioneMode() {
    try {

      if (localStorage.getItem("RendicontazioneMode") != null && localStorage.getItem("RendicontazioneMode") != undefined) {
        this.RendicontazioneMode=localStorage.getItem("RendicontazioneMode");
        return localStorage.getItem("RendicontazioneMode");
      }
    } catch (error) {
    }
    this.RendicontazioneMode="range";
    if(this.GETPIVA()=="12345678912"){
      this.RendicontazioneMode="hour";
      return "hour";
    }
    return "range";
  }


  navigateback() {
    if (this.isEdit) {
    this.isEdit = false;
    } else {
      this.navCtrl.back();
    }
  }
  constructor(private navCtrl: NavController) { }

}
