import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AnagraficheService } from '../services/anagrafiche.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-anagraficheedit',
  templateUrl: './anagraficheedit.page.html',
  styleUrls: ['./anagraficheedit.page.scss'],
})
export class AnagraficheeditPage implements OnInit {

  
  public IDAnagrafica;
  public Anagrafica:any =[];

  constructor(public httpClient: HttpClient,  private route: ActivatedRoute, private router: Router, private _mysrv: AnagraficheService, private navCtrl: NavController, public _myglobal: GlobalService) {
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    //if (this._myglobal.IDLicenzaAnagrafica==null || this._myglobal.IDLicenzaAnagrafica<=0)
    {
      this.navCtrl.navigateRoot('/login');
    }
    this.route.paramMap.subscribe(params => {
      this.IDAnagrafica = params.get('idanagrafica');
   });
  }

  
  
  _loadAnagrafica(){
    this._mysrv.loadAnagrafica(this.IDAnagrafica).subscribe((data : any) => {
      this.Anagrafica = data[0];
      console.log(this.Anagrafica);
    }, error => {
      console.log(error);
    });
 }

 
 ngOnInit() {
  this._loadAnagrafica();
}

ionViewDidEnter(){
  this._myglobal.GETUserData();
}

public doRefresh(event) {
  this._loadAnagrafica();
  event.target.complete();
}

// navback(){
//     this.navCtrl.navigateRoot('/tabs/tab1');
// }


}