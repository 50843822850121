import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSelect, LoadingController, NavController } from '@ionic/angular';
import { DocumentiService } from '../services/documenti.service';
import { GlobalService } from '../services/global.service';
import { ModalController } from '@ionic/angular';
import {  CalendarModal,  CalendarModalOptions,  DayConfig,  CalendarResult} from 'ion2-calendar';
import { DatePipe } from '@angular/common';
import { IonInfiniteScroll } from '@ionic/angular';
import { IonContent } from '@ionic/angular';
import { DxDataGridComponent } from "devextreme-angular";
import DataSource from 'devextreme/data/data_source';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-bncmagazzinorighe',
  templateUrl: './bncmagazzinorighe.page.html',
  styleUrls: ['./bncmagazzinorighe.page.scss'],
})
export class BncmagazzinorighePage implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) pageTop: IonContent;
  @ViewChild(IonSelect) mygruppo: IonSelect;


  public IDTipoGruppo: string="";
  public IDGruppoSistema;
  
  public mySearch: string ="";
  public DocumentiAll: any = [];
  public BNCMovimentiRighe: any = [];
  public BNCMovimentiRigheTemp: any = [];
  
  public Gruppi: any = [];

  
  public PageNo: number=0;
  public Limit:number=30;
  public InVendita: boolean=false;
  public ProntiPerFusione: boolean=false;
  public VisualizzaTutti: boolean=false;
  public selFilter : any = 'Magazzino';
  public selFilterData : any = 'Tutte le Date';

  constructor(public datepipe: DatePipe, public modalCtrl: ModalController, public httpClient: HttpClient, private router: Router, private _mysrv: DocumentiService, private navCtrl: NavController, public _myglobal: GlobalService, public loadingController: LoadingController) {
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    //if (this._myglobal.IDLicenzaAnagrafica==null || this._myglobal.IDLicenzaAnagrafica<=0)
    {
      this.navCtrl.navigateRoot('/login');
    }
    
  }
 
  ngOnInit(): void {
     
      this.refreshData();
        
  }

  documenti$ : any;
  dsDocumenti : any;
  public Documenti:any = [];
  refreshData(){
    
      //this.documenti$ =this._mysrv.loadBNCMagazzinoRighe(this.selFilter, this.selFilterData);
    if (this.selFilter=="Tutti"){

      this.dsDocumenti = new DataSource({
        load: ()=>{
          return(firstValueFrom(this._mysrv.loadBNCMagazzinoRigheAll("")) as Promise<any>)
        }
      });

      // this.Documenti = [];
      // this._mysrv.loadBNCMagazzinoRigheAll("").subscribe({
      // next:(data:any)=>{
      //   this.Documenti = data;
      //   console.log('DOCUMENTI -> ', this.Documenti)
      //   }
      // })
    } else {
      this.dsDocumenti = new DataSource({
        load: ()=>{
          return(firstValueFrom(this._mysrv.loadBNCMagazzinoRighe(this.selFilter, this.selFilterData)) as Promise<any>)
        }
      });

      // this.Documenti = [];
      // this._mysrv.loadBNCMagazzinoRighe(this.selFilter, this.selFilterData).subscribe({
      // next:(data:any)=>{
      //   this.Documenti = data;
      //   console.log('DOCUMENTI -> ', this.Documenti)
      //   }
      // })
    }
     
    return;
    
  }


  loadData(reload){
    if (reload) {
      this.PageNo=0;
      this.BNCMovimentiRighe=[];
      this.BNCMovimentiRigheTemp=[];
    }
    this.PageNo++;
    this._mysrv.loadBNCMagazzinoRighe(this.selFilter, this.selFilterData).subscribe((data : any) => {
      console.log('Data:' +data);
      this.BNCMovimentiRighe=  data ;
     
    }, error => {
      console.log(error);
    });

  }

  doInfinite(event) {
    if (this.mySearch.length==0) {
      this.loadData(false);
      event.target.complete();
    }
  }

   
 
  dataFine: string =   this.datepipe.transform(new Date(), 'yyyy-MM-dd'); // new Date().toISOString();
  dataInizio: string = this.datepipe.transform(new Date(new Date().getFullYear(), 0, 0), 'yyyy-MM-dd'); 
  

  public doRefresh(event) {
    this.loadData(true);    
    // this._loadGruppi();
    event.target.complete();
  }
 
  
  clearLayout() {
    localStorage.removeItem("grdBNCMagazzinoRighe");
    window.location.reload();
  }

  customizeText(cellInfo:any) {
    // console.log("CELLINFO : ", cellInfo);
      return cellInfo.valueText.replaceAll("\n", "<br/>\r\n");
  }
  
  ionViewDidEnter() {
     this._myglobal.GETUserData();
   }

 


}
