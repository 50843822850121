import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AnagraficheService } from '../services/anagrafiche.service';
import { GlobalService } from '../services/global.service';
import { IonInfiniteScroll } from '@ionic/angular';



@Component({
  selector: 'app-tab1',
  templateUrl: 'tab1.page.html',
  styleUrls: ['tab1.page.scss']
})
export class Tab1Page {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;


  public IDGruppoSistema: string = "CLI";
  public mySearch: string ="";
  public Anagrafiche: any = [];
  public AnagraficheTemp: any = [];

  public PageNo: number=1;
  public Limit:number=30;

  constructor(public httpClient: HttpClient, private router: Router, private _mysrv: AnagraficheService, private navCtrl: NavController, public _myglobal: GlobalService) {
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    //if (this._myglobal.IDLicenzaAnagrafica==null || this._myglobal.IDLicenzaAnagrafica<=0)
    {
      this.navCtrl.navigateRoot('/login');
    }
  }

  loadData(){
    this._mysrv.loadAnagrafiche(this.IDGruppoSistema, this.PageNo, this.Limit, this.mySearch, true);
    this._mysrv.Evento.subscribe(data => {
      console.log(this.PageNo + '_' + data.length);
      for (let i = 0; i < data.length; i++) {
        this.Anagrafiche.push(data[i]);
      }
      if (this.mySearch.length==0){
        this.AnagraficheTemp= this.Anagrafiche;
        
      }
    });
    
  }

  doInfinite(event) {
    if (this.mySearch.length==0) {
      this.loadData();
      console.log(this.Anagrafiche)
      event.target.complete();
      this.PageNo++;
    }
  }

  onInput(evt) {

  console.log('input:' + evt.target.value)
    this.mySearch=evt.target.value;
    if (this.mySearch.length>2) {
      this.infiniteScroll.disabled = true;
     this._mysrv.loadAnagraficheSearch(this.IDGruppoSistema,0,0, this.mySearch).subscribe((data : any) => {
      this.Anagrafiche = data;
    }, error => {
      console.log(error);
    });
    } else if (this.mySearch.length==0) {
      this.Anagrafiche=this.AnagraficheTemp;
      this.infiniteScroll.disabled = false;
    }
     
  }

  onCancel(evt) {
    console.log('cancel:'+evt.target.value);
    this.mySearch="";
    this.Anagrafiche=this.AnagraficheTemp;
    this.infiniteScroll.disabled = false;
  } 

  public doRefresh(event) {
    if (this.mySearch.length==0) {
    this.loadData();
    event.target.complete();
    }
  }

  navigate(id){
    this.navCtrl.navigateForward(['/tabs/anagraficheedit', id]);
  }
 
  ngOnInit(){  
    this.loadData();
  }
 
  ionViewDidEnter(){
    this._myglobal.GETUserData();
  }
  
  onChangeType() {
  this.mySearch="";
  this.Anagrafiche = [];
  this.AnagraficheTemp = [];
  this.PageNo=1;
  this.loadData();
  }

}
