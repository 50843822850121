import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

import { DropdownSearchComponent, ModalContent } from "./dropdown-search.component";
import { RouterModule } from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
    ],
    declarations: [DropdownSearchComponent, ModalContent],
    exports: [DropdownSearchComponent, ModalContent]
})
export class DropdownSearchModule {}