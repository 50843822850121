import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DocumentifeService {
  @Output() Evento = new EventEmitter;

  constructor(private httpClient: HttpClient,private _global: GlobalService, public loadingController: LoadingController) { }


  async loadINVIATE(DataDal, DataAl) { 
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    let postData = {
      "CliKey": this._global.ClientKey, 
      "Dal": DataDal,
      "Al" : DataAl 
  }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL_FE + "FE_GetListINVIATEDate", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
        loading.dismiss();
       }, error => {
        console.log(error);
        loading.dismiss();
      });
  }

  async loadRICEVUTE(DataDal, DataAl) { 
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    let postData = {
      "CliKey": this._global.ClientKey , 
      "Dal": DataDal,
      "Al" : DataAl 
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL_FE + "FE_GetListRICEVUTEDate", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
        loading.dismiss();
       }, error => {
        console.log(error);
        loading.dismiss();
      });
  }


  async loadDAVALIDARE(DataDal, DataAl) { 
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    let postData = {
      "CliKey": this._global.ClientKey , 
      "Dal": DataDal,
      "Al" : DataAl 
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL_FE + "FE_GetListDAVALIDARE", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
        loading.dismiss();
       }, error => {
        console.log(error);
        loading.dismiss();
      });
  }

  async loadVALIDAZIONENEGATA(DataDal, DataAl) { 
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    let postData = {
      "CliKey": this._global.ClientKey , 
      "Dal": DataDal,
      "Al" : DataAl 
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL_FE + "FE_GetListVALIDAZIONENEGATA", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
        loading.dismiss();
       }, error => {
        console.log(error);
        loading.dismiss();
      });
  }



  Download_Documento(NomeDoc) { 
    let postData = {
      "CliKey": this._global.ClientKey,
      "nomeDoc" : NomeDoc
  }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL_FE + "FE_GetDocumento", postData, headers) 
  }

  

  StoreFile(NomeDoc, b64, CreaZip) {
    this._global.GETendpointCURRENT();
    let postData = {
      "nomeFile": NomeDoc,
      "b64File" : b64,
      "CreateZip" : CreaZip,
      "IDUtenteMaster" : this._global.IDUtenteMaster
  }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "StoreFile", postData, headers) 
  }

  
  StoreFileHTML(NomeDoc, b64, CreaZip) {
    this._global.GETendpointCURRENT();
    let postData = {
      "nomeFile": NomeDoc,
      "b64File" : b64,
      "CreateZip" : CreaZip,
      "IDUtenteMaster" : this._global.IDUtenteMaster
  }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GetHtmlFromXml", postData, headers) 
  }



  Download_DocumentiINVIATI(DataDal, DataAl) {
    let postData = {
      "CliKey": this._global.ClientKey,
      "Dal": DataDal,
      "Al" : DataAl
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL_FE + "FE_GetDocumentiINVIATI", postData, headers) 
  }


  Download_DocumentiRICEVUTI(DataDal, DataAl) {
    let postData = {
      "CliKey": this._global.ClientKey,
      "Dal": DataDal,
      "Al" : DataAl
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL_FE + "FE_GetDocumentiRICEVUTI", postData, headers) 
  }

  

  ListDocumentiINVIATIbyData(DataDal, DataAl) {
    let postData = {
      "CliKey": this._global.ClientKey,
      "Dal": DataDal,
      "Al" : DataAl
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL_FE + "FE_GetListINVIATEDate", postData, headers) 
  }


  ListDocumentiRICEVUTIbyData(DataDal, DataAl) {
    let postData = {
      "CliKey": this._global.ClientKey,
      "Dal": DataDal,
      "Al" : DataAl
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL_FE + "FE_GetListRICEVUTEDate", postData, headers) 
  }

  ListDocumentiDAVALIDAREByData(DataDal, DataAl) {
    let postData = {
      "CliKey": this._global.ClientKey,
      "Dal": DataDal,
      "Al" : DataAl
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL_FE + "FE_GetListDAVALIDARE", postData, headers) 
  }
  
  ListDocumentiVALIDAZIONENEGATAByData(DataDal, DataAl) {
    let postData = {
      "CliKey": this._global.ClientKey,
      "Dal": DataDal,
      "Al" : DataAl
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL_FE + "FE_GetListVALIDAZIONENEGATA", postData, headers) 
  }

  GetFEDownloaded() {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDUtente" : this._global.IDUtenteMaster,

  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "GetFEDownloaded", postData, headers) 
  }


  InsertFEDownloaded(NomeDoc) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDUtente" : this._global.IDUtenteMaster,
      "NomeDoc" : NomeDoc

  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "InsertFEDownloaded", postData, headers) 
  }


  DeleteFEDownloaded(NomeDoc) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDUtente" : this._global.IDUtenteMaster,
      "NomeDoc" : NomeDoc

  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "DeleteFEDownloaded", postData, headers) 
  }



  Valida_Documento(NomeDoc) {
    let postData = {
      "CliKey": this._global.ClientKey,
      "nomeDoc" : NomeDoc
  }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL_FE + "FE_ValidaDocumento", postData, headers) 
  }

  ValidazioneNegata_Documento(NomeDoc) {
    let postData = {
      "CliKey": this._global.ClientKey,
      "nomeDoc" : NomeDoc
  }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL_FE + "FE_ValidazioneNegataDocumento", postData, headers) 
  }

}
