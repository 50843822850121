import { HttpClient } from '@angular/common/http';
import { Component, NgModule, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSelect, LoadingController, NavController, AlertController, ToastController, IonSelectOption, IonText, Platform } from '@ionic/angular';
import { RendicontazioneService } from '../../services/rendicontazione.service';
import { RendicontazioneCloudService } from 'src/app/services/rendicontazione-cloud.service';
import { GlobalService } from '../../services/global.service';
import { ModalController } from '@ionic/angular';
import { CalendarModal, CalendarModalOptions, DayConfig, CalendarResult, CalendarComponentOptions } from 'ion2-calendar';
import { DatePipe } from '@angular/common';
import { IonInfiniteScroll } from '@ionic/angular';
import { IonContent } from '@ionic/angular';
import { AnagraficheService } from '../../services/anagrafiche.service';
import { firstValueFrom } from 'rxjs';
import moment from 'moment';  

@Component({
  selector: 'app-rendicontazioni-list',
  templateUrl: './rendicontazioni-list.page.html',
  styleUrls: ['./rendicontazioni-list.page.scss'],
})


export class RendicontazioniListPage {
  
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('cmbDip') cmbDip: IonSelect;
  
  slidesOptions = {
    slidesPerView:2.2
  }

  intervalId: any;

  startFunction() {
    this.intervalId = setInterval(() => {
      console.log('Function is running...');
      // Metti qui il tuo codice da eseguire
    }, 100); // Esegue la funzione ogni 100 millisecondi
  }

  stopFunction() {
    clearInterval(this.intervalId);
    console.log('Function stopped');
  }
  
  dataOption = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  public current_data: string = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  public IDDipendente: number = 0;
  public ListDipendenti: any = [];
  public ListAttivitaSumTipo: any = [];
  public ListAttivitaSumTotale: any = [];
  public ListAttivitaSumTotaleMese: any = [];
  public ListCommesse: any = [];
  public ListCommesseClienti: any = [];
  public ListFasi: any = [];
  public AttivitaTipologie: any = [];
  public AttivitaPianificate: any = [];

  public ListAttivitaMese: any = [];
  public ListAttivita: any = [];
  public selAttivita: any = [];
  public selIDAttivita;

  public ListRendicontazioni: any = [];
  public selIDRendicontazione;
  public selRendicontazione;

  public SaveMode: string = "EDIT";
  public cnMode : string ='';
  public isIOS : boolean = false;
  public isDaysShow : boolean = true;

  // public bShowNotAssigned: boolean = false;
  //public bLoadNotAssigned: boolean = false;
  public bCopyToDate: boolean = false;
  public CopyToDate: string = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

  public dateRange: { from: "2024-01-01"; to: "2024-02-01" };

  optionsRange: CalendarComponentOptions = {
      showMonthPicker: false,
      showToggleButtons: false,
      daysConfig: [
        { date: new Date(), subTitle: '5 ore', cssClass: 'badge' }
      ]
    };
//#region CALENDAR MODAL
  async openCalendar() {
    const loading = await this.loadingController.create({
      message: 'Caricamento...',
    });
    await loading.present();
    let defDate;
    defDate = this.current_data;

    const options: CalendarModalOptions = {
      title: '',
      color: 'primary',
      canBackwardsSelected: true,
      from: new Date(2017, 1, 1),
      defaultDate: defDate,
      defaultScrollTo: defDate
    };

   

    let myCalendar = await this.modalCtrl.create({
      component: CalendarModal,
      componentProps: { options }
    });

    myCalendar.present();

    loading.dismiss();

    const event: any = await myCalendar.onDidDismiss();
    const date: CalendarResult = event.data;
    this.current_data = this.datepipe.transform(date.dateObj, 'yyyy-MM-dd');
  }
//#endregion

  constructor(public platform: Platform, public datepipe: DatePipe, public modalCtrl: ModalController, public alertCtrl: AlertController, public toastController: ToastController, public httpClient: HttpClient, private router: Router, private _mysrv: RendicontazioneService,  private _mysrvCloud: RendicontazioneCloudService,  private navCtrl: NavController, public _myglobal: GlobalService, private _srvanag: AnagraficheService, public loadingController: LoadingController) {
    this._myglobal.GETUserData();
    this.cnMode = _myglobal.GETcnMode();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    {
      this.navCtrl.navigateRoot('/login');
    }

    if(this.platform.is("ios") || this.platform.is("mobileweb") ){
      this.isIOS=true;
    }
    this.quarterHourOptions = this.generateQuarterHourOptions();
    
    this._myglobal.GETRendicontazioneMode();
    this.editRenMode= this._myglobal.RendicontazioneMode;

  }

  ionViewDidEnter(){
    this._myglobal.GETUserData();
    this.RefreshSquential();
  }

  ionViewDidLeave(){
    console.log("ION LEAVE");
    this.ListAttivita=[];
    this.selAttivita = {};
  
  }

   ngOnInit() {
  //   this._myglobal.GETUserData();
  //   this.RefreshSquential();
  this.platform.keyboardDidShow.subscribe(() => {
    // Scorrere il contenuto verso l'alto o verso il basso quando la tastiera è aperta
    this.content.scrollToBottom(300); // Regolare la durata dello scrolling se necessario
  });

  this.platform.keyboardDidHide.subscribe(() => {
    // Ripristinare la posizione originale del contenuto quando la tastiera viene chiusa
    this.content.scrollToTop(300); // Regolare la durata dello scrolling se necessario
  });  
}

  async RefreshSquential() {
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    this.ListAttivita=[];
    //CARICO I DIPENDENTI
    let IDDipendenteLogged;
    IDDipendenteLogged = this._myglobal.GETIDDipendente();
    if (IDDipendenteLogged>0 && this._myglobal.GETUserType() == "ADMIN") {
      IDDipendenteLogged = -9999;
    }
    if (this.cnMode=="LOCAL") {
      this._mysrv.Get_Anagrafiche("DIP", IDDipendenteLogged).subscribe((data : any) => {
              this.ListDipendenti = data;
              this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
              console.log('LOAD DIPENDENTI');
              console.log (this.ListDipendenti);
              loading.dismiss();
              this.RefreshDataSquential();
          }, error => {
            console.log(error);
            loading.dismiss();
          });
    }
    if (this.cnMode=="CLOUD") {
      this._mysrvCloud.Get_Anagrafiche("DIP", IDDipendenteLogged).subscribe((data : any) => {
              this.ListDipendenti = data;
              this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
              console.log('LOAD DIPENDENTI');
              console.log (this.ListDipendenti);
              loading.dismiss();
              this.RefreshDataSquential();
          }, error => {
            console.log(error);
            loading.dismiss();
          });
    }
  }

  async RefreshDataSquential() {
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
        this.ListAttivita=[];
        this.selAttivita = {};
        this.ListRendicontazioni=[];
        this.selRendicontazione = {};
        if (this.cnMode=="LOCAL") {
        //CARICA I CLIENTI COMMESSE
        this._mysrv.Get_CommesseClienti(this.IDDipendente, false).subscribe((data : any) => {
          this.ListCommesseClienti = data;
          console.log('LOAD CLI COMM');
          console.log (this.ListCommesseClienti);
          loading.dismiss();
          this._loadAttivita();
           
        }, error => {
          console.log(error);
          loading.dismiss();
        }); 
      }
      if (this.cnMode=="CLOUD") {
       
        //   //CARICA I CLIENTI COMMESSE
        this._mysrvCloud.Get_CommesseClienti(this.IDDipendente).subscribe((data : any) => {
          this.ListCommesseClienti = data;
          console.log('LOAD CLI COMM');
          console.log (this.ListCommesseClienti);
          loading.dismiss();
          this._loadAttivita();

        //   //CARICO LE FASI DI COMMESSA ASSOCIATE AL DIPENDENTE SELEZIONATO
        //   this._mysrvCloud.Get_Commesse(this.IDDipendente, -9999).subscribe((data : any) => {
        //     this.ListCommesse = data;
        //     console.log('LOAD Comm');
        //     console.log (this.ListFasi);

        //     this._mysrvCloud.Get_CommesseFasi(this.current_data, this.IDDipendente, -1, 1).subscribe((data : any) => {
        //       this.ListFasi = data;
        //       if (this.SaveMode=="ADD") {
        //         this.ListFasi.forEach(f => {
        //           if (f.IsDefault) {
        //             this.selAttivita.IDCommessaFase=f.IDFase;
        //           } else {
        //             this.selAttivita.IDCommessaFase=-1;
        //           }
        //         });
        //       } 
        //       console.log('LOAD FasiComm');
        //       console.log (this.ListFasi);
        //       loading.dismiss();
        //       this._loadAttivita();
        //   }, error => {
        //     console.log(error);
        //     loading.dismiss();
        //   });
        //   }, error => {
        //   console.log(error);
        //   loading.dismiss();
        //   });
        }, error => {
          console.log(error);
          loading.dismiss();
        }); 
      }
  }

  doRefresh(event) {
    this.RefreshDataSquential();
    event.target.complete();
   }
 
   async _loadAttivita(){
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    //CARICO LE ATTIVITA PER IL DIPENDENTE E DATA
    this.isloading=true;
    console.log('PRE LOAD ATTIVITA : IDDipendente : ' + this.IDDipendente);
    this.ListAttivita = [];
    this.selAttivita = {};
    this.ListRendicontazioni = [];
    this.selRendicontazione = {};
    //this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
    //console.log(' LOAD ATTIVITA : IDDipendente : ' + this.IDDipendente);
    if (this.cnMode=="LOCAL") {
      this._mysrv.Get_Attivita(this.IDDipendente, this.current_data).subscribe((data : any) => {
          this.ListAttivita = data;
         
          this.isloading=false;
          console.log('LOAD ATTIVITA');
          console.log (this.ListAttivita);
          loading.dismiss();
          this._loadAttivitaMeseSum();
          this._loadAttivitaSummaries();
      }, error => {
        this.isloading=false;
        console.log(error);
        loading.dismiss();
      });
    }
    if (this.cnMode=="CLOUD") {
      this._mysrvCloud.Get_Rendicontazioni(-1,-1,this.current_data, this.IDDipendente, "").subscribe((data : any) => {
          this.ListRendicontazioni = data;
          // this.ListRendicontazioni.forEach(el => {
        
          //   if (el.DataOraInizio==null || el.DataOraInizio==undefined || parseInt(el.DataOraInizio.slice(0,4))<=1970) { el.DataOraInizio ="";}
          //   if (el.DataOraFine==null || el.DataOraFine==undefined || parseInt(el.DataOraFine.slice(0,4))<=1970) { el.DataOraFine ="";}
          // });
          this.isloading=false;
          console.log('LOAD ATTIVITA');
          console.log (this.ListRendicontazioni);
          loading.dismiss();
          this._loadAttivitaMeseSum();
          this._loadAttivitaSummaries();
      }, error => {
        this.isloading=false;
        console.log(error);
        loading.dismiss();
      });
    }
  } 


  
  async _loadAttivitaMeseSum(){
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    //CARICO LE ATTIVITA PER IL DIPENDENTE E DATA
    this.isloading=true;
    console.log('PRE LOAD ATTIVITA MESE SUM : IDDipendente : ' + this.IDDipendente);
    this.ListAttivitaMese = [];  
    if (this.cnMode=="LOCAL") {
      this._mysrv.Get_Attivita_Mese_Sum(this.IDDipendente, this.datepipe.transform(this.current_data, 'yyyy'), this.datepipe.transform(this.current_data, 'MM')).subscribe((data : any) => {
          this.ListAttivitaMese = data;
          this.isloading=false;
          console.log('LOAD ATTIVITA MESE');
          console.log (this.ListAttivitaMese);
          loading.dismiss();
      }, error => {
        this.isloading=false;
        console.log(error);
        loading.dismiss();
      });
      this._mysrv.SUM_Attivita_Totale_Mese(this.IDDipendente, this.datepipe.transform(this.current_data, 'yyyy'), this.datepipe.transform(this.current_data, 'MM')).subscribe((data : any) => {
        this.ListAttivitaSumTotaleMese = data;
        console.log('LOAD ATTIVITA TOTALE MESE');
        console.log (this.ListAttivitaSumTotaleMese);
      }, error => {
        console.log(error);
      });
    }
    if (this.cnMode=="CLOUD") {
      this._mysrvCloud.Get_Attivita_Mese_Sum(this.IDDipendente, this.datepipe.transform(this.current_data, 'yyyy'), this.datepipe.transform(this.current_data, 'MM')).subscribe((data : any) => {
          this.ListAttivitaMese = data;
          this.isloading=false;
          console.log('LOAD ATTIVITA MESE');
          console.log (this.ListAttivitaMese);
          loading.dismiss();
      }, error => {
        this.isloading=false;
        console.log(error);
        loading.dismiss();
      });
      this._mysrvCloud.SUM_Attivita_Totale_Mese(this.IDDipendente,  this.datepipe.transform(this.current_data, 'yyyy'), this.datepipe.transform(this.current_data, 'MM')).subscribe((data : any) => {
        this.ListAttivitaSumTotaleMese = data;
        console.log('LOAD ATTIVITA TOTALE MESE');
        console.log (this.ListAttivitaSumTotaleMese);
      }, error => {
        console.log(error);
      });
    }
  } 


  async _loadAttivitaSummaries(){
    //CARICO LE ATTIVITA PER IL DIPENDENTE E DATA
   
    if (this.cnMode=="LOCAL") {
      this._mysrv.SUM_Attivita_Per_Tipo(this.IDDipendente, this.current_data).subscribe((data : any) => {
        this.ListAttivitaSumTipo = data;
        console.log('LOAD ATTIVITA SUM Tipi');
        console.log (this.ListAttivitaSumTipo);
      }, error => {
        console.log(error);
      });
        this._mysrv.SUM_Attivita_Totale(this.IDDipendente, this.current_data).subscribe((data : any) => {
          this.ListAttivitaSumTotale = data;
          console.log('LOAD ATTIVITA TOTALE');
          console.log (this.ListAttivitaSumTotale);
      }, error => {
        console.log(error);
      });
      
    }
    if (this.cnMode=="CLOUD") {
        this._mysrvCloud.SUM_Attivita_Totale(this.IDDipendente, this.current_data).subscribe((data : any) => {
          this.ListAttivitaSumTotale = data;
          console.log('LOAD ATTIVITA TOTALE CLOUD');
          console.log (this.ListAttivitaSumTotale);
      }, error => {
        console.log(error);
      });
    }
  }

  async _loadDipendenti(){
    try {
        //CARICO I DIPENDENTI
        let IDDipendenteLogged;
        IDDipendenteLogged = this._myglobal.GETIDDipendente();
        if (IDDipendenteLogged > 0 && this._myglobal.GETUserType() == "ADMIN") {
            IDDipendenteLogged = -9999;
        }
        
        let data;
        if (this.cnMode === "LOCAL") {
            data = await firstValueFrom(this._mysrv.Get_Anagrafiche("DIP", IDDipendenteLogged));
        } else if (this.cnMode === "CLOUD") {
            data = await firstValueFrom(this._mysrvCloud.Get_Anagrafiche("DIP", IDDipendenteLogged));
        }
        
        this.ListDipendenti = data;
        this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
        console.log('LOAD DIPENDENTI');
        console.log(this.ListDipendenti);
    } catch (error) {
        console.log(error);
    }
}


async checkboxCommesseNotAssignedClick(){
  if(this.isloading==true) {return;}
  this.selAttivita.NotAssigned = !this.selAttivita.NotAssigned;
  // this.selAttivita.IDOggettoOperatore = -1
  // this.selAttivita.IDCommessaFase = -1;
  //await this._loadCommesseClienti();
  // await this._loadCommesse(this.selAttivita.IDOggettoOperatore,-9999);
  // await this._loadFasiCommesse(this.selAttivita.IDOggettoOperatore,this.selAttivita.IDOggettoGeneratore);
  console.log('bShowNotAssigned', this.selAttivita.NotAssigned)
}

LoadingCombo : boolean = false;
async chgNotAssign(){
  this.LoadingCombo = true;
  // this.selAttivita.IDOggettoOperatore = null;
  // this.selAttivita.IDOggettoGeneratore= null;
  // this.selAttivita.IDCommessaFase = null;
  await this.chgDip();
  this.LoadingCombo = false;
}
async chgDip(){
  this.LoadingCombo = true;
  console.log('chgDip : ' + this.isloading) 
  if(this.isloading==true) {return;}
  await this._loadCommesseClienti();
  if (this.cnMode === "LOCAL") {
    // this.bLoadNotAssigned=!this.selAttivita.NotAssigned;
    await this._loadCommesse(this.selAttivita.IDOggettoOperatore,this.selAttivita.IDCliente);
    console.log('chgDip : Commesse : ' + this.selAttivita.IDOggettoOperatore + ' ' + this.selAttivita.IDCliente, this.ListCommesse)
    await this._loadFasiCommesse(this.selAttivita.IDOggettoOperatore,this.selAttivita.IDOggettoGeneratore);
  }
  if (this.cnMode === "CLOUD") {
    
    await this._loadCommesse(this.selRendicontazione.IDDipendente,this.selRendicontazione.IDCliente, this.selRendicontazione.IDOggettoGeneratore);
    await this._loadFasiCommesse(this.selRendicontazione.IDDipendente,this.selRendicontazione.IDCommessa);
  }
  this.LoadingCombo = false;
}
async chgCli(){
  this.LoadingCombo = true;
  this.selAttivita.IDOggettoGeneratore= null;
  this.selAttivita.IDCommessaFase = null; 

  if(this.isloading==true) {return;}
  if (this.cnMode === "LOCAL") {
  await this._loadCommesse(this.selAttivita.IDOggettoOperatore, this.selAttivita.IDCliente);
  await this._loadFasiCommesse(this.selAttivita.IDOggettoOperatore,this.selAttivita.IDOggettoGeneratore);
  console.log('chgCli : Commesse : ' + this.selAttivita.IDOggettoOperatore + ' ' + this.selAttivita.IDCliente, this.ListCommesse)
  }
  if (this.cnMode === "CLOUD") {
    
  this.selRendicontazione.IDCommessa= null;
  this.selRendicontazione.IDFase = null;
    await this._loadCommesse(this.selRendicontazione.IDDipendente, this.selRendicontazione.IDCliente, this.selRendicontazione.IDOggettoGeneratore);
    await this._loadFasiCommesse(this.selRendicontazione.IDDipendente,this.selRendicontazione.IDCommessa);
    console.log('chgCli : Commesse : ' + this.selAttivita.IDDipendente + ' ' + this.selRendicontazione.IDCliente, this.ListCommesse)
    }
  this.LoadingCombo = false;
}
async chgCom(){
  this.LoadingCombo = true;
  if(this.isloading==true) {return;}
  this.selAttivita.IDCommessaFase = null;
  if (this.cnMode === "LOCAL") {
  await this._loadFasiCommesse(this.selAttivita.IDOggettoOperatore,this.selAttivita.IDOggettoGeneratore);
  }
  if (this.cnMode === "CLOUD") {
    this.selRendicontazione.IDFase = null;
    console .log("IDCommessa pre api : " + this.selRendicontazione.IDCommessa);
    
    await this._loadFasiCommesse(this.selRendicontazione.IDDipendente,this.selRendicontazione.IDCommessa);
    console .log("IDCommessa post api : " + this.selRendicontazione.IDCommessa);
    

    }
  this.LoadingCombo = false;
}

  async _loadCommesse(IDDipendente, IDCliente, FromChange: boolean = false ){
      //CARICO LE FASI DI COMMESSA ASSOCIATE AL DIPENDENTE SELEZIONATO
      try {
          if (this.cnMode === "LOCAL") {
            this.ListCommesse = [];
            this.ListCommesse = await firstValueFrom(this._mysrv.Get_Commesse(IDDipendente, IDCliente, this.selAttivita.NotAssigned));
             
            if (this.SaveMode === "ADD" && this.ListCommesse.length == 1) {
              this.selAttivita.IDOggettoGeneratore = this.ListCommesse[0].IDCommessa;
            }  
               
              //this.ListCommesse = await firstValueFrom(this._mysrv.Get_Commesse(IDDipendente, IDCliente));
           
              console.log('LOAD Comm', IDDipendente, IDCliente);
              console.log(this.ListFasi);
          } else if (this.cnMode === "CLOUD") {
            if (FromChange) {
              //this.selRendicontazione.IDFase = -1;
            }this.ListCommesse = [];
              this.ListCommesse = await firstValueFrom(this._mysrvCloud.Get_Commesse(IDDipendente, IDCliente));
              if (this.SaveMode === "ADD") {
                this.selRendicontazione.IDCommessa = this.ListCommesse[0].IDCommessa;
            }
              console.log('LOAD Comm', IDDipendente, IDCliente);
              console.log(this.ListFasi);
          }
      } catch (error) {
          console.log(error);
      }
  }
  
  async _loadFasiCommesse(IDDipendente, IDCommessa){
      //CARICO LE FASI DI COMMESSA ASSOCIATE AL DIPENDENTE SELEZIONATO
      try {
          if (this.cnMode === "LOCAL") {
              this.ListFasi= [];
              this.ListFasi = await firstValueFrom(this._mysrv.Get_CommesseFasi(this.current_data, IDDipendente, IDCommessa, 1, this.selAttivita.NotAssigned));
              
              //if (this.SaveMode === "ADD") {
                  // this.ListFasi.forEach(f => {
                  //     if (f.IsDefault) {
                  //         this.selAttivita.IDCommessaFase = f.IDFase;
                  //     } else {
                  //         this.selAttivita.IDCommessaFase = this.ListFasi[0].IDFase;
                  //     }
                  // });
                  if (this.ListFasi.length==1) {
                    this.selAttivita.IDCommessaFase = this.ListFasi[0].IDFase;
                  }
              //}
              console.log('LOAD FasiComm');
              console.log(this.ListFasi);
          } else if (this.cnMode === "CLOUD") {
            //this.selRendicontazione.IDFase = -1;
            this.ListFasi= [];
            this.ListFasi = await firstValueFrom(this._mysrvCloud.Get_CommesseFasi(this.current_data, IDDipendente, IDCommessa, 1));
              if (this.SaveMode === "ADD") {
                  this.ListFasi.forEach(f => {
                      if (f.IsDefault) {
                          this.selRendicontazione.IDFase = f.IDFase;
                      } else {
                          this.selRendicontazione.IDFase = -1;
                      }
                  });
                  // if (this.ListFasi.length==1) {
                  //   this.selRendicontazione.IDFase = this.ListFasi[0].IDFase;
                  // }
              }
              console.log('LOAD FasiComm');
              console.log(this.ListFasi);
          }
      } catch (error) {
          console.log(error);
      }
  }
  async _loadCommesseClienti(){
    //CARICA I CLIENTI COMMESSE
    const loading = await this.loadingController.create({
        message: 'Caricamento Commesse...',
    });
    await loading.present();

    let data;
    try {
        if (this.cnMode === "LOCAL") {
             data = await firstValueFrom(this._mysrv.Get_CommesseClienti(this.IDDipendente, this.selAttivita.NotAssigned));
        } else if (this.cnMode === "CLOUD") {
            data = await firstValueFrom(this._mysrvCloud.Get_CommesseClienti(this.IDDipendente));
        }

        this.ListCommesseClienti = data;
        console.log('LOAD CLI COMM');
        console.log(this.ListCommesseClienti);
    } catch (error) {
        console.log(error);
    } finally {
        loading.dismiss();  
    }
  }


  // getFilteredNotAssigned(data : any): any[] {
  //   if (!data) {
  //     return [];
  //   }
  //   if (!this.bShowNotAssigned) {
  //     return data.filter(item => item.Assigned == this.bShowNotAssigned);
  //   } else {
  //     return data;
  //   }
  // }


  navigateback() {
    if (this._myglobal.isEdit) {
    this._myglobal.isEdit = false;
    this.selAttivita = {};
    this.ListAttivita =[];
    this.selRendicontazione = {};
    this.ListRendicontazioni = [];
    this._loadAttivita();
    } else {
      this.navCtrl.back();
    }
  }

  // async _loadAttivitaTipologie(){
  //   //CARICO LE TIPOLOGIE DELLE ATTIVITA
  //   this._mysrv.Get_AttivitaTipologie().subscribe((data : any) => {
  //       this.AttivitaTipologie = data;
  //   }, error => {
  //     console.log(error);
  //   });

  // }

  // async _loadAttivitaPianificate(IDCommessaFase){
  //   //CARICO LE TIPOLOGIE DELLE ATTIVITA
  //   this._mysrv.Get_AttivitaPianificate(IDCommessaFase).subscribe((data : any) => {
  //       this.AttivitaPianificate = data;
  //   }, error => {
  //     console.log(error);
  //   });

  // }

  public editRenMode="";

  async selectAttivita(a){
    //CARICO L'ATTIVITA SELEZIONATA IN VARIABILE TEMPORANEA
    this.isloading=true; 
    // this.bShowNotAssigned=false;
    this._myglobal.GETRendicontazioneMode();
    this.editRenMode= this._myglobal.RendicontazioneMode;
    console.log('ISLOADING : ' + this.isloading);
    this.SaveMode="EDIT";
    this._myglobal.isEdit = true;
    this.quarterHourOptions = this.generateQuarterHourOptions();
    if (this.cnMode=="LOCAL") {

       //await this._loadCommesseClienti();
       await this._loadCommesse(a.IDOggettoOperatore, a.IDCliente, a.IDOggettoGeneratore);
       console.log('selectAttivita : Commesse : ' + a.IDOggettoOperatore + ' ' + a.IDCliente, this.ListCommesse)
       await this._loadFasiCommesse(a.IDOggettoOperatore, a.IDOggettoGeneratore);

      // let CommessaFind = this.ListCommesse.find(item => item.IDCommessa === a.IDOggettoGeneratore);

      // this.ListCommesse.forEach(commessa => {
      //   if (commessa.IDCommessa == a.IDOggettoGeneratore) {
      //     const isAssigned = commessa.Assigned;
      //     if (isAssigned == false) {
      //       this.bShowNotAssigned = true;
      //     }
      //   }  
      // });
      // if (this.ListCommesse == null || this.ListCommesse.length<=0){
      //   //this.bShowNotAssigned = true;
        // this.bShowNotAssigned = true;
      //   await this._loadCommesseClienti();
      //   await this._loadCommesse(-9999, a.IDCliente);
      //   console.log('selectAttivita : Commesse Not assigned: ', this.ListCommesse)
      //   await this._loadFasiCommesse(-9999, a.IDOggettoGeneratore);
      // }

      this.selIDAttivita = a.IDAttivita;
      this.selAttivita = [];

      if (!this.quarterHourOptions.includes(a.DataOraInizioTime)) {
        this.quarterHourOptions.push(a.DataOraInizioTime);
      }
      if (!this.quarterHourOptions.includes(a.DataOraFineTime)) {
          this.quarterHourOptions.push(a.DataOraFineTime);
      }
      //this._mysrv.Get_Attivita_ByIDAttivita(a.IDAttivita).subscribe((data : any) => {
      //  this.selAttivita = data[0];
       this.selAttivita = a;
        console.log('SELECT ATTIVITA');
      console.log(this.selAttivita);
      this.selRendicontazione = [];
      this.selIDRendicontazione = -1;
      // if ((this.selAttivita.DataOraInizio.slice(11,16) == null || this.selAttivita.DataOraInizio.slice(11,16) == undefined || this.selAttivita.DataOraInizio.slice(11,16) == 'undefined' || this.selAttivita.DataOraInizio.slice(11,16) == '00:00' ) &&
      // (this.selAttivita.DataOraFine.slice(11,16) == null || this.selAttivita.DataOraFine.slice(11,16) == undefined || this.selAttivita.DataOraFine.slice(11,16) == 'undefined' || this.selAttivita.DataOraFine.slice(11,16) == '00:00' )) {
      //   this.editRenMode="hour";
      // } else {
      //     this.editRenMode="range";
      // }
     
      // this.selAttivita = a;
      
      console.log('ISLOADING : ' + this.isloading);
      this.isloading=false;
      // }, error => {
      // });
     
      
    }
    if (this.cnMode=="CLOUD") {
      this.selAttivita = [];
      await this._loadCommesse(a.IDDipendente,a.IDCliente, a.IDCommessa);
      await this._loadFasiCommesse(a.IDDipendente, a.IDCommessa);

      if (!this.quarterHourOptions.includes(a.DataOraInizioTime)) {
        this.quarterHourOptions.push(a.DataOraInizioTime);
      }
      if (!this.quarterHourOptions.includes(a.DataOraFineTime)) {
          this.quarterHourOptions.push(a.DataOraFineTime);
      }
      console.log('SELECT ATT ADD HOURS : ', a.DataOraInizioTime, this.quarterHourOptions);

      this.selRendicontazione = a;
      // if ((this.selRendicontazione.DataOraInizio.slice(11,16) == null || this.selRendicontazione.DataOraInizio.slice(11,16) == undefined || this.selRendicontazione.DataOraInizio.slice(11,16) == 'undefined' || this.selRendicontazione.DataOraInizio.slice(11,16) == '00:00' ) &&
      // (this.selRendicontazione.DataOraFine.slice(11,16) == null || this.selRendicontazione.DataOraFine.slice(11,16) == undefined || this.selRendicontazione.DataOraFine.slice(11,16) == 'undefined' || this.selRendicontazione.DataOraFine.slice(11,16) == '00:00' )) {
      //   this.editRenMode="hour";
      // } else {
      //   if ( this._myglobal.RendicontazioneMode=="range" ||  this._myglobal.RendicontazioneMode =="rangess" ) {
      //     this.editRenMode=this._myglobal.RendicontazioneMode;
      //   } else {
      //     this.editRenMode="range";
      //   }
      // }
      

      this.selIDAttivita = -1;

      
      console.log('SELECT RENDIC', this.selRendicontazione);
      this.isloading=false;
    }
  }

  addAttivita() {
    //IMPOSTO LA MOPDALITA ADD E CARICO I DATI DI DEFAULT PER INSERT
    
    // this.bShowNotAssigned=false;
    this._myglobal.GETRendicontazioneMode();
    this.editRenMode= this._myglobal.RendicontazioneMode;
    let myData = this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
    if (this._myglobal.RendicontazioneMode=="hour") {
      myData = this.datepipe.transform(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(), 0,0),"yyyy-MM-dd HH:mm");
    }
    if (this.current_data != null && this.current_data != undefined && this.current_data != "") { myData = this.current_data;} 
    this.isloading=true;
    this.SaveMode = "ADD";
    this.selRendicontazione = [];
    this.selIDRendicontazione = -1;
    this.ListFasi = [];
    this.ListCommesse = [];
    this.selAttivita = {
      "IDAttivita" : -1,
      "IDAttivitaCausale" : -1,
      "CodiceCausale" : "ORD",
      "IDOggettoOperatore" : this.IDDipendente,
      "IDOggettoGeneratore" : -1,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "DataOraInizio" :  this.current_data,
      "DataOraFine" : this.current_data,
      "Qta" : 0.0, 
      "QtaDurata" : new Date(new Date(new Date()).getFullYear(),new Date(new Date()).getMonth(),new Date(new Date()).getDate(), 0, 0),
      "IDCommessaFase" : -1,
      "NotAssigned" : false
    };
    this.selIDAttivita = -1;
    this._myglobal.isEdit=true;
    this.isloading=false;
    console.log('ADD ATTIVITA');
      console.log(this.selAttivita);
  }
  addRendicontazione() {
    this._myglobal.GETRendicontazioneMode();
    this.editRenMode= this._myglobal.RendicontazioneMode;
    this._myglobal.isEdit=true;
    let myData = this.datepipe.transform(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(), 0,0),"yyyy-MM-dd HH:mm");
      this.isloading=true;
      this.SaveMode="ADD";
      this.selAttivita = [];
      this.selIDAttivita = -1;
      this.selRendicontazione = {
        "IDRendicontazione" : -1,
        "IDLicenzaAnagrafica" : this._myglobal.IDLicenzaAnagrafica,
        "CodiceUnita" : this._myglobal.CodiceUnita,
        "PIVA" : this._myglobal.PIVA,
        "CodiceCausale" : "ORD",
        "IDDipendente" : this.IDDipendente,
        "IDCommessa" : -1,
        "IDFase" : -1,
        "Data" :  myData,
        "Ore" : 0.0, 
        "OreDurata" : new Date(new Date(new Date()).getFullYear(),new Date(new Date()).getMonth(),new Date(new Date()).getDate(), 0, 0),
        "DataOraInizio" : new Date(),
        "DataOraFine" : new Date(),
        "Note" : "",
        "Stato" : "INSERITO"
      };
      this.selIDRendicontazione = -1;
      this._myglobal.isEdit=true;
      this.isloading=false;
      console.log('ADD ATTIVITA');
      console.log(this.selRendicontazione);
  }

  init_worked_time() {
    var m = moment().utcOffset(0);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    m.toISOString();
    m.format();
    console.log(m);
    this.selRendicontazione.OreDurata = m;
    this.selAttivita.QtaDurata = m;
    console.log(this.selRendicontazione)
  }

  async DEL_Attivita(x) {
  //ELIMINAZIONE DELL ATTIVITA
    let alert = await this.alertCtrl.create({
      header: 'Next App',
      message: "Eliminare questa attivita?",
      buttons: [
        {
          text: 'Annulla', role: 'cancel', handler: () => { } },
        {
          text: 'Conferma',
          handler: () => {
            this.isloading=true;
             this._mysrv.DEL_Attivita(x.IDAttivita).subscribe((data : any) => {
              this._myglobal.isEdit = false;
              this.isloading=false;
              this.ListAttivita = [];
              this.ListRendicontazioni = [];
              this._loadAttivita(); 
              console.log('DEL Attivita');
              this.presentAlert('Attività eliminata!');
            }, error => {
              this.isloading=false;
              console.log(error);
            });
          }
        }
      ]
    });
    await alert.present();
  }


  saveAttivita(x){
    //SALVO l'ATTIVITA 

    if (this.bCopyToDate) {
      this.isloading=true;
      console.log(this.SaveMode + ' ' + x.IDAttivita)
      const startDate = new Date(x.DataOraInizio);
      const endDate = new Date(this.CopyToDate);
      const datesToProcess: Date[] = [];
      const currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const formattedDayOfWeek = this.datepipe.transform(currentDate, 'EEEE');
        if (formattedDayOfWeek !== 'Saturday' && formattedDayOfWeek !== 'Sunday') {
          datesToProcess.push(new Date(currentDate));
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      for (const date of datesToProcess) {
      if ((this.SaveMode=="EDIT" && x.IDAttivita>0) || (this.SaveMode=="ADD")) {
          //x.DataOraInizio = date;
          console.log('Save Attivita pre ' + date, moment(date).format('yyyy-MM-DD'));
          x.DataOraInizio = moment(date).format('yyyy-MM-DD');
          console.log('Save Attivita '  , x);

            this._mysrv.UPD_Attivita(x).subscribe((data : any) => {
            this.selIDAttivita=-1;
            this.selAttivita={};
            this._myglobal.isEdit = false;
            this.SaveMode="EDIT";
            this.isloading=false;
            this.ListAttivita = [];
            this._loadAttivita(); 
            this.msg = "Attivita salvata!";
            this.showToast("success");
            //this.presentAlert('Attivita salvata!');
          }, error => {
            this.isloading=false;
            console.log(error);
          });
       }
      }
      return;
    }


    this.isloading=true;
    console.log(this.SaveMode + ' ' + x.IDAttivita)
    console.log(' SAVE UPD : ' + JSON.stringify(x))
    
     
    if ((x.IDOggettoGeneratore == null || x.IDOggettoGeneratore<=0) && this.ListCommesse.length>0) {
      this.msg = "Inserire una Commessa!";
      this.showToast("danger");
      return;
    }
    console.log("Pre cntrollo fasi", x, this.ListFasi);
    if ((x.IDCommessaFase == null || x.IDCommessaFase<=0) && this.ListFasi.length>0) {
      this.msg = "Inserire una Fase di Commessa!";
      this.showToast("danger");
      return;
    }

     if ((this.SaveMode=="EDIT" && x.IDAttivita>0) || (this.SaveMode=="ADD")) {
        this._mysrv.UPD_Attivita(x).subscribe((data : any) => {
          this.selIDAttivita=-1;
          this.selAttivita={};
          this._myglobal.isEdit = false;
          this.SaveMode="EDIT";
          this.isloading=false;
          this.ListAttivita = [];
          this._loadAttivita(); 
          this.msg = "Attivita salvata!";
          this.showToast("success");
          //this.presentAlert('Attivita salvata!');
        }, error => {
          this.isloading=false;
          console.log(error);
        });
      }
  }

   
  
start(x){
  if(this.isloading==true) {return;}
  this.isloading=true;
  x.DataOraInizio=this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
  this.isloading=false;
}

stop(x){
  if(this.isloading==true) {return;}
  this.isloading=true;
  x.DataOraFine=this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
  this.isloading=false;
}


editRendicontazione(x) {
  this.isloading=true;
  this.SaveMode="EDIT";
  this._myglobal.isEdit=true;
  this._loadCommesse(x.IDDipendente, x.IDCliente, x.IDCommessa).then((result) => {
    this._loadFasiCommesse(x.IDCommessa, x.IDDipendente).then((result) => {
      this.selRendicontazione = x;
    });
  });
  
  this.isloading=false;
}



async _saveRendicontazione(x){
  //SALVO
  if (x.IDCommessa == null || x.IDCommessa == '' || x.IDCommessa<=0) {
    this.presentAlert('Selezionare una commessa!');
    return;
  }
  if (x.IDFase == null || x.IDFase == '' || x.IDFase<=0) {
    this.presentAlert('Selezionare una fase di commessa!');
    return;
  }
  if (this.editRenMode !="rangess" && (x.Ore == null || x.Ore == '' || x.Ore<=0)) {
    this.presentAlert('Impostare una durata!');
    return;
  }
  if (this.editRenMode =="rangess") {
    if (x.DataOraInizio==null || x.DataOraInizio==undefined) { x.DataOraInizio="";}
    if (x.DataOraFine==null || x.DataOraFine==undefined) { x.DataOraFine="";}
    let dtStart: string ="";
    dtStart = x.DataOraInizio;
    let dtEnd: string ="";
    dtEnd = x.DataOraFine;
    console.log('AVE REND:', dtStart, dtEnd)
    if ((dtStart.slice(11,16) == '00:00' || dtStart=="" || dtStart =='') ||
    (dtEnd.slice(11,16) == '00:00' || dtEnd=="" || dtEnd == '')) {
      console.log('INTO IN CORSO');    
      x.Stato = "IN CORSO";
    } else {
       if (x.Stato == "IN CORSO") {
          x.Stato = "INSERITO";
        }
    }
  }
  //if (x.Data == null || x.Data == '' || x.Data==undefined) {
     x.Data = this.datepipe.transform(new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate()),"yyyy-MM-dd");
  //}
  console.log('RET SAVE REND PREV:', x, x.Stato);
  // let alert = await this.alertCtrl.create({
  //   header: 'Next App',
  //   message: "Salvare la rendicontazione?" ,
  //   buttons: [
  //     { text: 'Annulla', role: 'cancel', handler: () => { }
  //     },
  //     {
  //       text: 'Conferma',
  //       handler: () => {
            
  //         }  
  //       }
  //   ]
  // });
  // await alert.present();
  

  this._mysrvCloud.Save_Rendicontazioni([x]).subscribe((data : any) => {
        console.log('RET SAVE REND' , data);
        this._myglobal.isEdit = false;
        this.SaveMode="EDIT";
        if (data == null || String(data).startsWith("ERRORE") || String(data[0].Ret).startsWith("ERRORE")) {
          this.presentAlert(String(data[0].Ret));
        } else {
          
        }
        this.ListAttivita = [];
        this.ListRendicontazioni = [];
        this._loadAttivita();
    }, error => {
      console.log(error);
    });


}


async DEL_Rendicontazione(x) {
  //ELIMINAZIONE DELL ATTIVITA
  let alert = await this.alertCtrl.create({
    header: 'Next App',
    message: "Eliminare la rendicontazione?",
    buttons: [
      {
        text: 'Annulla', role: 'cancel', handler: () => { } },
      {
        text: 'Conferma',
        handler: () => {
          this.isloading=true;
           this._mysrvCloud.DELRendicontazione(x.IDRendicontazione).subscribe((data : any) => {
            this._myglobal.isEdit = false;
            this.isloading=false;
            this.ListAttivita = [];
            this.ListRendicontazioni = [];
            this._loadAttivita(); 
            console.log('DEL Rendicontazione');
            this.presentAlert('Rendicontazione eliminata!');
          }, error => {
            this.isloading=false;
            console.log(error);
          });
        }
      }
    ]
  });
  await alert.present();
  }
  
 
//#region "CALCOLI TEMPI" 
// FUNZIONI PER IL CALCOLO DEI TEMPI E DATE
// isloading: boolean = false;
// calcTimerFromFine(x) {
//   if (this.cnMode=="LOCAL") {
//     console.log('CALC TIMER > ISLOADING : ' + this.isloading);
//     if(this.isloading==true) {return;}
//     this.isloading=true;
//     console.log(x.DataOraInizio + ' ' + x.DataOraFine);
//     x.DataOraInizio = this.datepipe.transform(new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraInizio).getHours(), new Date(x.DataOraInizio).getMinutes()),"yyyy-MM-dd HH:mm");
//     x.DataOraFine = this.datepipe.transform(new Date(new Date(x.DataOraFine).getFullYear(),new Date(x.DataOraFine).getMonth(),new Date(x.DataOraFine).getDate(), new Date(x.DataOraFine).getHours(), new Date(x.DataOraFine).getMinutes()),"yyyy-MM-dd HH:mm");
//     console.log(x.DataOraInizio + ' ' + x.DataOraFine);
//     let diff=this.msToTime(new Date(x.DataOraFine).getTime() - new Date(x.DataOraInizio).getTime());
//     console.log('DIFF: ' + diff + '    H:' +diff.split(':')[0]);
//     x.QtaDurata=  diff.split(':')[0] + ':' + diff.split(':')[1];
//     console.log('QtaDurata: ' + x.QtaDurata);
//     this.isloading=false;
//   }
//   if (this.cnMode=="CLOUD") {
// //    return;
//     console.log('CALC TIMER > ISLOADING : ' + this.isloading, x.DataOraFine.slice(0,4));
//     if(this.isloading==true) {return;}
//     let dtEnd: string | null ="";
//     dtEnd = x.DataOraFine.toString();
//     if (parseInt(dtEnd.slice(0,4))<=1970 || dtEnd==null || dtEnd==undefined || dtEnd=="" ) {  return;}
//       this.isloading=true;
//       console.log(x.DataOraInizio + ' ' + x.DataOraFine);
//       x.DataOraInizio = this.datepipe.transform(new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraInizio).getHours(), new Date(x.DataOraInizio).getMinutes()),"yyyy-MM-dd HH:mm");
//       x.DataOraFine = this.datepipe.transform(new Date(new Date(x.DataOraFine).getFullYear(),new Date(x.DataOraFine).getMonth(),new Date(x.DataOraFine).getDate(), new Date(x.DataOraFine).getHours(), new Date(x.DataOraFine).getMinutes()),"yyyy-MM-dd HH:mm");
//       console.log(x.DataOraInizio + ' ' + x.DataOraFine);
//       let diff=this.msToTime(new Date(x.DataOraFine).getTime() - new Date(x.DataOraInizio).getTime());
//       console.log('DIFF: ' + diff + '    H:' +diff.split(':')[0]);
//       x.OreDurata=  diff.split(':')[0] + ':' + diff.split(':')[1];
//       console.log('QtaDurata: ' + x.OreDurata);
//       this.isloading=false;
//     }
// }
// calcTimerFromOre(x) {
//   if (this.cnMode=="LOCAL") {
//     console.log('CALC TIMER FROM ORE > ISLOADING : ' + this.isloading);
//     if(this.isloading==true) {return;}
//     this.isloading=true;
//     x.DataOraFine = this.datepipe.transform(new Date(x.DataOraInizio).setMinutes(new Date(x.DataOraInizio).getMinutes() + (parseFloat(x.Qta)*60)),"yyyy-MM-dd HH:mm");
//     this.isloading=false;
//   }
//   if (this.cnMode=="CLOUD") {
//     //return;
//     console.log('CALC TIMER FROM ORE > ISLOADING : ' + this.isloading, x.DataOraFine.slice(0,4));
//     if(this.isloading==true) {return;}
//     let dtEnd: string ="";
//     dtEnd = x.DataOraFine.toString();
//     if (parseInt(dtEnd.slice(0,4))<=1970 || dtEnd==null || dtEnd==undefined || dtEnd=="" ) {  return;}
//     this.isloading=true;
//     //alert(x.DataOraFine);
//     x.DataOraFine = this.datepipe.transform(new Date(x.DataOraInizio).setMinutes(new Date(x.DataOraInizio).getMinutes() + (parseFloat(x.Ore)*60)),"yyyy-MM-dd HH:mm");
//     this.isloading=false;
//   }
// }
// calcDurationToHours(x) {
//   if (this.cnMode=="LOCAL") {
//     console.log('CALC TIMER TO ORE > ISLOADING : ' + this.isloading);
//     if(this.isloading==true) {return;}
//     this.isloading=true;
//     if (x.QtaDurata!=null && x.QtaDurata!=undefined && x.QtaDurata!='undefined' && x.QtaDurata!="") {
//     let a = x.QtaDurata.split(':');
//     let h = parseInt(a[0]);
//     let m = parseInt(a[1]);
//     if (h>=0 && m>=0) {
//       x.Qta = h + (m/60);
//       x.Qta= x.Qta.toFixed(2);
//       console.log('DURATA: ' + x.QtaDurata + '  QTA: ' + x.Qta);
//       }
//     }
//   this.isloading=false;
//   }
//   if (this.cnMode=="CLOUD") {
//     //return;
//     console.log('CALC TIMER TO ORE > ISLOADING : ' + this.isloading);
//     // let dtEnd: string ="";
//     // dtEnd = x.DataOraFine.toString();
//     //if (parseInt(dtEnd.slice(0,4))<=1970 || dtEnd==null || dtEnd==undefined || dtEnd=="" ) {  return;}
//     if(this.isloading==true) {return;}
//     this.isloading=true;
//     if (x.OreDurata!=null && x.OreDurata!=undefined && x.OreDurata!='undefined' && x.OreDurata!="") {
//     let a = x.OreDurata.split(':');
//     let h = parseInt(a[0]);
//     let m = parseInt(a[1]);
//     if (h>=0 && m>=0) {
//         x.Ore = h + (m/60);
//         x.Ore= x.Ore.toFixed(2);
//         console.log('DURATA: ' + x.OreDurata + '  QTA: ' + x.Ore);
//       }
//     }
//     this.isloading=false;
//   }
// }
// msToTime(s) {
//   console.log(s);
//   let ms = s % 1000;
//   s = (s - ms) / 1000;
//   var secs = s % 60;
//   s = (s - secs) / 60;
//   var mins = (s % 60) ;
//   var hrs = (s - mins) / 60;
//   return hrs + ':' + mins;
// }

//#endregion



//#region "CALCOLI TEMPI"
// FUNZIONI PER IL CALCOLO DEI TEMPI E DATE
isloading: boolean = false;
calcTimerFromFine(x:any) {
  if(this.isloading==true) {return;}
  this.isloading=true;
    console.log('calcTimerFromFine1 > ISLOADING : ' , x.QtaDurata , x.Qta, x.DataOraInizio, x.DataOraFine);
    x.DataOraInizio = new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraInizio).getHours(), new Date(x.DataOraInizio).getMinutes());
    x.DataOraFine = new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraFine).getHours(), new Date(x.DataOraFine).getMinutes());
    let diff=this.msToTime(new Date(x.DataOraFine).getTime() - new Date(x.DataOraInizio).getTime());
    //this.QtaDurata1= this.pad(parseInt(diff.split(':')[0]), 2) + ':' +  this.pad(parseInt(diff.split(':')[1]),2);
    x.QtaDurata =  new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), parseInt(diff.split(':')[0]), parseInt(diff.split(':')[1]));
    console.log('calcTimerFromFine1 > QtaDurata1 : ' , x.QtaDurata);
    if (x.QtaDurata!=null && x.QtaDurata!=undefined && x.QtaDurata!='undefined' && x.QtaDurata!="") {
      x.Qta = this.getDecimalFromTime(x.QtaDurata);
      console.log('calcTimerFromFine1 > RESULT : ' , x.QtaDurata, x.Qta, x.DataOraInizio, x.DataOraFine);
  }
  this.isloading=false;
}
calcDurationToHours(x:any) {
  if(this.isloading==true) {return;}
  this.isloading=true;
    console.log('calcDurationToHours1 > ISLOADING : ' , x , x.QtaDurata , x.Qta, x.DataOraInizio, x.DataOraFine);
    if (x.QtaDurata!=null && x.QtaDurata!=undefined && x.QtaDurata!='undefined' && x.QtaDurata!="") {
      x.Qta = this.getDecimalFromTime(x.QtaDurata);
      console.log('calcDurationToHours1 > AFTER CALC : ' , x.QtaDurata , x.Qta, x.DataOraInizio, x.DataOraFine);
      x.DataOraFine = new Date(new Date(x.DataOraInizio).setMinutes(new Date(x.DataOraInizio).getMinutes() + (parseFloat(x.Qta)*60)));
      console.log('calcDurationToHours1 > RESULT : ', x.QtaDurata , x.Qta, x.DataOraInizio, x.DataOraFine);
    }
    this.isloading=false;
}
calcDurationToHoursCloud(x:any) {
  if(this.isloading==true) {return;}
  this.isloading=true;
    console.log('calcDurationToHours1 > ISLOADING : ' , x , x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
    if (x.OreDurata!=null && x.OreDurata!=undefined && x.OreDurata!='undefined' && x.OreDurata!="") {
      x.Ore = this.getDecimalFromTime(x.OreDurata);
      console.log('calcDurationToHours1 > AFTER CALC : ' , x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
      x.DataOraFine = new Date(new Date(x.DataOraInizio).setMinutes(new Date(x.DataOraInizio).getMinutes() + (parseFloat(x.Ore)*60)));
      console.log('calcDurationToHours1 > RESULT : ', x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
    }
    this.isloading=false;
}
calcTimerFromFineR(x:any) {
  if(this.isloading==true) {return;}
  this.isloading=true;
    console.log('calcTimerFromFine1 > ISLOADING : ' , x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
    x.DataOraInizio = new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraInizio).getHours(), new Date(x.DataOraInizio).getMinutes());
    x.DataOraFine = new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), new Date(x.DataOraFine).getHours(), new Date(x.DataOraFine).getMinutes());
    let diff=this.msToTime(new Date(x.DataOraFine).getTime() - new Date(x.DataOraInizio).getTime());
    //this.OreDurata1= this.pad(parseInt(diff.split(':')[0]), 2) + ':' +  this.pad(parseInt(diff.split(':')[1]),2);
    x.OreDurata =  new Date(new Date(x.DataOraInizio).getFullYear(),new Date(x.DataOraInizio).getMonth(),new Date(x.DataOraInizio).getDate(), parseInt(diff.split(':')[0]), parseInt(diff.split(':')[1]));
    console.log('calcTimerFromFine1 > OreDurata1 : ' , x.OreDurata);
    if (x.OreDurata!=null && x.OreDurata!=undefined && x.OreDurata!='undefined' && x.OreDurata!="") {
      x.Ore = this.getDecimalFromTime(x.OreDurata);
      console.log('calcTimerFromFine1 > RESULT : ' , x.OreDurata, x.Ore, x.DataOraInizio, x.DataOraFine);
  }
  this.isloading=false;
}
calcDurationToHoursR(x:any) {
  if(this.isloading==true) {return;}
  this.isloading=true;
    console.log('calcDurationToHours1 > ISLOADING : ' , x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
    if (x.OreDurata!=null && x.OreDurata!=undefined && x.OreDurata!='undefined' && x.OreDurata!="") {
      x.Ore = this.getDecimalFromTime(x.OreDurata);
      console.log('calcDurationToHours1 > AFTER CALC : ' , x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
      x.DataOraFine = new Date(new Date(x.DataOraInizio).setMinutes(new Date(x.DataOraInizio).getMinutes() + (parseFloat(x.Ore)*60)));
      console.log('calcDurationToHours1 > RESULT : ', x.OreDurata , x.Ore, x.DataOraInizio, x.DataOraFine);
    }
    this.isloading=false;
}
 

calcolaDurataNew(x:any): string {
  // Divide le stringhe di tempo in ore e minuti
  const [inizioOre, inizioMinuti] = x.DataOraInizioTime.split(':').map(Number);
  const [fineOre, fineMinuti] = x.DataOraFineTime.split(':').map(Number);

  // Calcola la differenza tra l'inizio e la fine in ore e minuti
  let ore = fineOre - inizioOre;
  let minuti = fineMinuti - inizioMinuti;

  // Controlla se i minuti sono negativi e aggiorna le ore di conseguenza
  if (minuti < 0) {
      minuti += 60;
      ore--;
  }

  // Formatta la durata
  const durata = `${ore.toString().padStart(2, '0')}:${minuti.toString().padStart(2, '0')}`;
  x.QtaDurata= durata;
  x.Qta= this.timeToDecimal(durata); 

  const nuovaDataInizio = new Date(new Date(x.DataOraInizio).getTime());
  nuovaDataInizio.setHours(inizioOre);
  nuovaDataInizio.setMinutes(inizioMinuti);
  nuovaDataInizio.setSeconds(0);
  
  const nuovaDataFine = new Date(new Date(x.DataOraFine).getTime());
  nuovaDataFine.setHours(fineOre);
  nuovaDataFine.setMinutes(fineMinuti);
  nuovaDataFine.setSeconds(0);

  x.DataOraInizio = nuovaDataInizio
  x.DataOraFine = nuovaDataFine

  console.log('calcolaDurataNew : ', x.DataOraInizioTime, x.DataOraFineTime, x.DataOraInizio, x.DataOraFine, x.QtaDurata, x.Qta);

  return durata;
}


aggiungiDurata(x:any): Date {
  // Divide la stringa di durata in ore e minuti
  const [durataOre, durataMinuti] = x.QtaDurata.split(':').map(Number);
  const [inizioOre, inizioMinuti] = x.DataOraInizioTime.split(':').map(Number);

  // Calcola le ore e i minuti totali sommando quelli di inizio e di durata
  let ore = inizioOre + durataOre;
  let minuti = inizioMinuti + durataMinuti;

  // Normalizza i minuti se superano 60
  if (minuti >= 60) {
      ore += Math.floor(minuti / 60);
      minuti %= 60;
  }

  // Formatta il nuovo tempo
  const nuovoTempo = `${ore.toString().padStart(2, '0')}:${minuti.toString().padStart(2, '0')}`;
  x.DataOraFineTime = nuovoTempo;

  // Crea una copia della data di inizio
  const nuovaDataFine = new Date(x.DataOraInizio.getTime());
  // Aggiunge la durata alla data di inizio
  nuovaDataFine.setHours(nuovaDataFine.getHours() + durataOre);
  nuovaDataFine.setMinutes(nuovaDataFine.getMinutes() + durataMinuti);
  nuovaDataFine.setSeconds(0);

  x.DataOraFine = nuovaDataFine

  
  console.log('aggiungiDurata : ', x.DataOraInizioTime, x.DataOraFineTime, x.DataOraInizio, x.DataOraFine, x.QtaDurata, x.Qta);



  return nuovaDataFine;
}


calcolaDurataCloudNew(x:any): string {
  // Divide le stringhe di tempo in ore e minuti
  const [inizioOre, inizioMinuti] = x.DataOraInizioTime.split(':').map(Number);
  const [fineOre, fineMinuti] = x.DataOraFineTime.split(':').map(Number);

  // Calcola la differenza tra l'inizio e la fine in ore e minuti
  let ore = fineOre - inizioOre;
  let minuti = fineMinuti - inizioMinuti;

  // Controlla se i minuti sono negativi e aggiorna le ore di conseguenza
  if (minuti < 0) {
      minuti += 60;
      ore--;
  }

  // Formatta la durata
  const durata = `${ore.toString().padStart(2, '0')}:${minuti.toString().padStart(2, '0')}`;
  x.OreDurata= durata;
  x.Ore= this.timeToDecimal(durata); 

  const nuovaDataInizio = new Date(new Date(x.DataOraInizio).getTime());
  nuovaDataInizio.setHours(inizioOre);
  nuovaDataInizio.setMinutes(inizioMinuti);
  nuovaDataInizio.setSeconds(0);
  
  const nuovaDataFine = new Date(new Date(x.DataOraFine).getTime());
  nuovaDataFine.setHours(fineOre);
  nuovaDataFine.setMinutes(fineMinuti);
  nuovaDataFine.setSeconds(0);

  x.DataOraInizio = nuovaDataInizio
  x.DataOraFine = nuovaDataFine

  console.log('calcolaDurataNew : ', x.DataOraInizioTime, x.DataOraFineTime, x.DataOraInizio, x.DataOraFine, x.OreDurata, x.Ore);

  return durata;
}


aggiungiDurataCloud(x:any): Date {
  // Divide la stringa di durata in ore e minuti
  const [durataOre, durataMinuti] = x.OreDurata.split(':').map(Number);
  const [inizioOre, inizioMinuti] = x.DataOraInizioTime.split(':').map(Number);

  // Calcola le ore e i minuti totali sommando quelli di inizio e di durata
  let ore = inizioOre + durataOre;
  let minuti = inizioMinuti + durataMinuti;

  // Normalizza i minuti se superano 60
  if (minuti >= 60) {
      ore += Math.floor(minuti / 60);
      minuti %= 60;
  }

  // Formatta il nuovo tempo
  const nuovoTempo = `${ore.toString().padStart(2, '0')}:${minuti.toString().padStart(2, '0')}`;
  x.DataOraFineTime = nuovoTempo;

  // Crea una copia della data di inizio
  const nuovaDataFine = new Date(x.DataOraInizio.getTime());
  // Aggiunge la durata alla data di inizio
  nuovaDataFine.setHours(nuovaDataFine.getHours() + durataOre);
  nuovaDataFine.setMinutes(nuovaDataFine.getMinutes() + durataMinuti);
  nuovaDataFine.setSeconds(0);

  x.DataOraFine = nuovaDataFine

  
  console.log('aggiungiDurata Cloud : ', x.DataOraInizioTime, x.DataOraFineTime, x.DataOraInizio, x.DataOraFine, x.OreDurata, x.Ore);



  return nuovaDataFine;
}



timeToDecimal(time: string): number {
  // Divide la stringa di tempo in ore e minuti
  const [oreStr, minutiStr] = time.split(':').map(Number);

  // Calcola la parte decimale dei minuti
  const minutiDecimali = minutiStr / 60;

  // Calcola il tempo totale in formato decimale
  const tempoDecimale = oreStr + minutiDecimali;

  return tempoDecimale;
}

///////////////////////////////////////////////////////////


getDecimalFromTime(sTime:string){
  var retTime:any;
  var dtTime=new Date(sTime);
  console.log('INTO CALC FUNCTION DECIMAL 1 :' ,  sTime, dtTime);
   var formattedDate;
  //  if (this.SaveMode=="EDIT") {
    formattedDate = sTime;
  //  } else {
  //   formattedDate = dtTime.toLocaleTimeString();
  //  }
   console.log('INTO CALC FUNCTION DECIMAL 2 :' ,  sTime, formattedDate);
   let a = formattedDate.toString().split(':');
   let h = parseInt(a[0]);
   let m = parseInt(a[1]);
   if (h>=0 && m>=0) {
     retTime = h + (m/60);
     retTime= parseFloat(retTime.toFixed(2));
     console.log('INTO CALC FUNCTION DECIMAL OK:' , retTime);
     //retTime = new Date(new Date(this.DataOraInizio).getFullYear(),new Date(this.DataOraInizio).getMonth(),new Date(this.DataOraInizio).getDate(), h, m);
     return retTime;
   }
}

msToTime(s:any) {
 console.log('msToTime',s);
 let ms = s % 1000;
 s = (s - ms) / 1000;
 var secs = s % 60;
 s = (s - secs) / 60;
 var mins = (s % 60) ;
 var hrs = (s - mins) / 60;
 return hrs + ':' + mins;
}

pad(num:number, size:number): string {
   let s = num+"";
   while (s.length < size) s = "0" + s;
   return s;
}
//#endregion


quarterHourOptions: string[] = [];

generateQuarterHourOptions(): string[] {
  const options: string[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute of ['00', '15', '30', '45']) {
      const time = `${hour.toString().padStart(2, '0')}:${minute}`;
      options.push(time);
    }
  }
  return options;
}


private msg = "";
public async showToast(_color: string) {

  const toast: any = await this.toastController.create({
    message: this.msg,
    duration: 1500,
    position: "top",
    color: _color
  });
  await toast.present();
  this.msg = "";
}




  async presentAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Next App',
      subHeader: '',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }



  checkboxClick(e){
     this.bCopyToDate = !this.bCopyToDate;
     console.log('chkCopyDate', this.bCopyToDate)
  }




}


 