import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { ArticoliService } from '../services/articoli.service';
import { GlobalService } from '../services/global.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-articoli-edit',
  templateUrl: './articoli-edit.page.html',
  styleUrls: ['./articoli-edit.page.scss'],
})
export class ArticoliEditPage implements OnInit {
  public IDArticolo;
  public Articolo: any = [];
  public Magazzini: any = [];
  public Causali: any = [];
  public Movimenti: any = [];
  public MovimentiToSync: any = [];
  public selRowCausale: any = [];
  public selIDMagazzino;
  public selIDMagazzinoDest;
  public selData: string = this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');;
  public selIDCausale; 
  public selQuantita; 
  public selNote;
  //public isEdit: boolean = false;
  public selTabs: string ="articolo";

  slidesOptions= {
    slidesPerView : 1.5,
  }

  constructor(public datepipe: DatePipe, public httpClient: HttpClient, private alertCtrl: AlertController,  private route: ActivatedRoute, private router: Router, public _mysrv: ArticoliService, private navCtrl: NavController, public loadingController: LoadingController, public _myglobal: GlobalService) { 
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    // if (this._myglobal.IDLicenzaAnagrafica==null || this._myglobal.IDLicenzaAnagrafica<=0)
    {
      this.navCtrl.navigateRoot('/login');
    }
    this.route.paramMap.subscribe(params => {
      this.IDArticolo = params.get('idarticolo');
      console.log('Art:'+this.IDArticolo);
   });
   this._myglobal.isEdit=false;
  }



  ngOnInit() {
    this._loadArticolo();
    this._loadMagazzini();
    this._loadCausali();

  }

  doRefresh(event) {
    this._loadArticolo();
    this._loadMagazzini();
    this._loadCausali();
    this.loadMovimenti();
    this.loadMovimentiToSync();
     event.target.complete();
    }

    ionViewDidEnter(){
      this._myglobal.GETUserData();
    }
  // back(){
  //   if (this.isEdit) {
  //     this.isEdit = false;
  //   } else {
  //   this.navCtrl.back();
  //   }
  // }

  _loadArticolo(){
    this._mysrv.loadArticolo(this.IDArticolo).subscribe((data)=>{
      this.Articolo = data[0];
      console.log(this.Articolo);
    },error => {
      console.log(error);
    })
    };

    _loadMagazzini(){
      this._mysrv.loadMagazzini().subscribe((data)=>{
        this.Magazzini = data;
        //this.selIDMagazzino = parseInt(this.Magazzini[0].IDMagazzino);
        console.log(this.Magazzini);
      },error => {
        console.log(error);
      })
    };

    _loadCausali(){
      this._mysrv.loadCausali().subscribe((data)=>{
        this.Causali = data;
        console.log(this.Causali);
      },error => {
        console.log(error);
      })
    };
    
    _loadCausale(){
      this._mysrv.loadCausale(this.selIDCausale).subscribe((data)=>{
        this.selRowCausale = data[0];
        console.log(this.selRowCausale);
      },error => {
        console.log(error);
      })
    };

    loadMovimenti(){
      this._mysrv.loadMovimenti(this.selIDMagazzino, this.Articolo.IDArticolo).subscribe((data)=>{
        this.Movimenti = data;
        let QtaRunning = 0;
        for (let i = 0; i <  this.Movimenti.length; i++) {
          const element =  this.Movimenti[i];
          element.Giacenza = QtaRunning + parseFloat(element.Quantita);
          QtaRunning = element.Giacenza;
        }
        console.log(this.Movimenti);
      },error => {
        console.log(error);
      })
    };

    loadMovimentiToSync(){
      this._mysrv.loadMovimentiToSync(this.selIDMagazzino, this.Articolo.IDArticolo).subscribe((data)=>{
        this.MovimentiToSync = data;
        console.log(this.MovimentiToSync);
      },error => {
        console.log(error);
      })
    };


    addMovimento()  {
      this._myglobal.isEdit= true;
    }


    private isloading: boolean=false;

    async INS_Movimento() {
    if (this.isloading) {return;}
    this.isloading = true;
      let alert = await this.alertCtrl.create({
        header: 'Next App',
        message: "Inserire il movimento?" ,
        buttons: [
          { text: 'Annulla', role: 'cancel', handler: () => { }
          },
          {
            text: 'Conferma',
            handler: () => {
              this._mysrv.INS_Movimenti( this.selData, this.selIDMagazzino, this.selIDMagazzinoDest, this.Articolo.IDArticolo, this.selIDCausale, this.selQuantita, this.selNote).subscribe((data)=>{
              this._myglobal.isEdit= false;
              this.isloading = false;
              this.loadMovimenti();  
              this.loadMovimentiToSync();      
            },error => {
              console.log(error);
              this.isloading = false;
            })
            }
          }
        ]
      });
      await alert.present();

      
    }

}
