import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ArticoliService } from '../services/articoli.service';
import { GlobalService } from '../services/global.service';
import { LoadingController } from '@ionic/angular';
import { IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-tab2',
  templateUrl: 'tab2.page.html',
  styleUrls: ['tab2.page.scss']
})
export class Tab2Page {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;


  public mySearch: string ="";
  public ArticoliAll: any = [];
  public Articoli: any = [];
  public ArticoliTemp: any = [];

  
  public PageNo: number=1;
  public Limit:number=30;


  constructor(public httpClient: HttpClient, private router: Router, private _mysrv: ArticoliService, private navCtrl: NavController, public loadingController: LoadingController, public _myglobal: GlobalService) {
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    //if (this._myglobal.IDLicenzaAnagrafica==null || this._myglobal.IDLicenzaAnagrafica<=0)
    {
      this.navCtrl.navigateRoot('/login');
    }
  }

 

  loadData(){
   
    this._mysrv.loadArticoli(this.PageNo, this.Limit, this.mySearch, true);
    this._mysrv.Evento.subscribe(data => {
      console.log(this.PageNo + '_' + data.length);
      if(this.PageNo==1) {this.Articoli=[];}
      for (let i = 0; i < data.length; i++) {
        this.Articoli.push(data[i]);
      }
      if (this.mySearch.length==0){
        this.ArticoliTemp= this.Articoli;
      }
    });
  }

  doInfinite(event) {
    console.log('_doInf');
    if (this.mySearch.length==0) {
      this.loadData();
      event.target.complete();
      this.PageNo++;
    }
  }

  onInput(evt) {
    console.log('_doInput');

    console.log('input:' + evt.target.value)
      this.mySearch=evt.target.value;
      if (this.mySearch.length>2) {
        this.infiniteScroll.disabled = true;
       this._mysrv.loadArticoliSearch(0,0, this.mySearch).subscribe((data : any) => {
        this.Articoli = data;
      }, error => {
        console.log(error);
      });
      } else if (this.mySearch.length==0) {
        this.Articoli=this.ArticoliTemp;
        this.infiniteScroll.disabled = false;
      }
       
    }
  
    onCancel(evt) {
      console.log('cancel:'+evt.target.value);
      this.mySearch="";
      this.Articoli=this.ArticoliTemp;
      this.infiniteScroll.disabled = false;
    } 
  
    public doRefresh(event) {
      if (this.mySearch.length==0) {
        console.log('_doRefresh');
      this.loadData();
      event.target.complete();
      }
    }

  navigate(id){
    this.navCtrl.navigateForward(['/tabs/articoli-edit', id]);
  }
 
  ngOnInit(){  
    console.log('_doInit');
    this.loadData();
  }
  ionViewDidEnter(){
    this._myglobal.GETUserData();
  }
 


}
