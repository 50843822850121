import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSelect, LoadingController, NavController } from '@ionic/angular';
import { DocumentiService } from '../services/documenti.service';
import { GlobalService } from '../services/global.service';
import { ModalController } from '@ionic/angular';
import {  CalendarModal,  CalendarModalOptions,  DayConfig,  CalendarResult} from 'ion2-calendar';
import { DatePipe } from '@angular/common';
import { IonInfiniteScroll } from '@ionic/angular';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-tab3',
  templateUrl: 'tab3.page.html',
  styleUrls: ['tab3.page.scss']
})
export class Tab3Page {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent) pageTop: IonContent;
  @ViewChild(IonSelect) mygruppo: IonSelect;


  public IDTipoGruppo: string="";
  public IDGruppoSistema;
  
  public mySearch: string ="";
  public DocumentiAll: any = [];
  public Documenti: any = [];
  public DocumentiTemp: any = [];
  
  public Gruppi: any = [];

  
  public PageNo: number=0;
  public Limit:number=30;

  constructor(public datepipe: DatePipe, public modalCtrl: ModalController, public httpClient: HttpClient, private router: Router, private _mysrv: DocumentiService, private navCtrl: NavController, public _myglobal: GlobalService, public loadingController: LoadingController) {
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    //if (this._myglobal.IDLicenzaAnagrafica==null || this._myglobal.IDLicenzaAnagrafica<=0)
    {
      this.navCtrl.navigateRoot('/login');
    }
    this._loadGruppi(); 
  }


  navigateXml(){
    this.router.navigate(['/documenti-xml']);
  }

  navigateDownloads(){
    this.router.navigate(['/downloads']);
  }
  
  async loadData(reload){
    if (reload) {
      this.PageNo=0;
      this.Documenti=[];
      this.DocumentiTemp=[];
    }
    this.PageNo++;
    this._mysrv.loadDocumentiSearch(this.IDTipoGruppo,this.IDGruppoSistema, this.dataInizio, this.dataFine, this.PageNo, this.Limit, this.mySearch, true).subscribe((data : any) => {
      console.log('PageNo:' + this.PageNo);
      if(this.PageNo<=1) {this.Documenti=[];}
       for (let i = 0; i < data.length; i++) {
         this.Documenti.push(data[i]);
       }
      if (this.mySearch.length==0){
        this.DocumentiTemp= this.Documenti;
      }
    }, error => {
      console.log(error);
    });

  }

  doInfinite(event) {
    if (this.mySearch.length==0) {
      this.loadData(false);
      event.target.complete();
    }
  }

  
  onInput(evt) {
    console.log('_doInput');

    console.log('input:' + evt.target.value)
      this.mySearch=evt.target.value;
      if (this.mySearch.length>2) {
        this.infiniteScroll.disabled = true;
       this._mysrv.loadDocumentiSearch(this.IDTipoGruppo,this.IDGruppoSistema, this.dataInizio, this.dataFine, 0, 0, this.mySearch, true).subscribe((data : any) => {
        this.Documenti = data;
      }, error => {
        console.log(error);
      });
      } else if (this.mySearch.length==0) {
        this.Documenti=this.DocumentiTemp;
        this.infiniteScroll.disabled = false;
      }
       
    }
  
    onCancel(evt) {
      console.log('cancel:'+evt.target.value);
      this.mySearch="";
      this.Documenti=this.DocumentiTemp;
      this.infiniteScroll.disabled = false;
    } 

  // onInput(evt) {
  //   console.log(evt.target.value);
  //   this.mySearch = evt.target.value;
  //   if (evt.target.value != null && evt.target.value.length>0) {
  //     this.Documenti = JSON.parse(JSON.stringify(this.DocumentiAll));
  //       let counter = 0;
  //       let Temp: any[] =[];
  //       Temp = Array.from(this.Documenti);
  //       Temp.forEach((item,index,obj) => {
  //         if (String(item.NrDocumento).toLowerCase().includes(this.mySearch.toLowerCase()) || String(item.DocumentoComposto).toLowerCase().includes(this.mySearch.toLowerCase()) || String(item.RagioneSociale).toLowerCase().includes(this.mySearch.toLowerCase())  || String(item.Totale).includes(this.mySearch.toLowerCase())) {
  //         } else{
  //           this.Documenti.splice(index-counter, 1);
  //           counter++;
  //         }
  //       });
  //   } else {
  //     this.Documenti = JSON.parse(JSON.stringify(this.DocumentiAll));
  //   }
  // }
  // onCancel(evt) {
  //   this.Documenti = this.DocumentiAll;
  // }

  dataFine: string =   this.datepipe.transform(new Date(), 'yyyy-MM-dd'); // new Date().toISOString();
  dataInizio: string = this.datepipe.transform(new Date(new Date().getFullYear(), 0, 0), 'yyyy-MM-dd'); 
 
  async openCalendar(datetoupdate) {
    const loading = await this.loadingController.create({
      message: 'Caricamento...',
    });
    await loading.present();
    let defDate;
    if (datetoupdate=='start') {
      defDate =  this.dataInizio;
    }
    if (datetoupdate=='end') {
      defDate =  this.dataFine;
    }
    
    const options: CalendarModalOptions = {
      title: '',
      color:'primary',
      canBackwardsSelected : true,
      from : new Date(2017,1, 1),
      defaultDate : defDate,
      defaultScrollTo : defDate
    };

  let myCalendar =  await this.modalCtrl.create({
    component: CalendarModal,
    componentProps: { options }
  });

  myCalendar.present();

  loading.dismiss();

  const event: any = await myCalendar.onDidDismiss();
    const date: CalendarResult = event.data;
    if (datetoupdate=='start') {
      this.dataInizio =  this.datepipe.transform(date.dateObj, 'yyyy-MM-dd');
      console.log( this.dataInizio);
    }
    if (datetoupdate=='end') {
      this.dataFine = this.datepipe.transform(date.dateObj, 'yyyy-MM-dd');
      console.log( this.dataFine);
    }
    
}

  public doRefresh(event) {
    this.loadData(true);    
    // this._loadGruppi();
    event.target.complete();
  }

  navigate(id){
    this.navCtrl.navigateForward(['/tabs/documentiedit', id]);
  }
 
  ngOnInit(){  
    this._loadGruppi(); 
     
     
  }

  ionViewDidEnter() {
     this._myglobal.GETUserData();
     this._loadGruppi();
     //this.loadData(true);   
   }


  _loadGruppi(){
    this._mysrv.loadGruppi(this.IDTipoGruppo).subscribe((data : any) => {
      this.Gruppi = data;
      
      for (let i = 0; i < data.length; i++) {
        if (String(data[i].IDGruppoSistema) == "FVN") {
          console.log(this.IDGruppoSistema);
          this.IDGruppoSistema = data[i].IDGruppoSistema;
          console.log(this.IDGruppoSistema);
         }
      }
     
      console.log(data);
    }, error => {
      console.log(error);
    });
  }


}
