import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSelect, LoadingController, NavController, AlertController, ToastController, IonSlide } from '@ionic/angular';
import { RendicontazioneService } from '../services/rendicontazione.service';
import { GlobalService } from '../services/global.service';
import { ModalController } from '@ionic/angular';
import {  CalendarModal,  CalendarModalOptions,  DayConfig,  CalendarResult} from 'ion2-calendar';
import { DatePipe } from '@angular/common';
import { IonInfiniteScroll } from '@ionic/angular';
import { IonContent } from '@ionic/angular';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-rendicontazioni',
  templateUrl: './rendicontazioni.page.html',
  styleUrls: ['./rendicontazioni.page.scss'],
})
export class RendicontazioniPage {
  @ViewChild(IonSlides) slides: IonSlides;
  //@ViewChild(IonSlide) slideORD: IonSlide;
  @ViewChild('cmbTipoRilevazioneStart') cmbTipoRilevazioneStart: IonSelect;
  @ViewChild('cmbStatoRilevazione') cmbStatoRilevazione: IonSelect;
  @ViewChild('cmbStatoRilevazioneCommessa') cmbStatoRilevazioneCommessa: IonSelect;
  @ViewChild('cmbDip') cmbDip: IonSelect;
  @ViewChild('mycurrenttime') sTime;

  dataOption = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  public current_data: string =   this.datepipe.transform(new Date(), 'yyyy-MM-dd');
 
  slidesOptions = {
    slidesPerView:1.3
  }

//#region CALENDAR MODAL
  async openCalendar() {
    const loading = await this.loadingController.create({
      message: 'Caricamento...',
    });
    await loading.present();
    let defDate;
    defDate =  this.current_data;
    
    const options: CalendarModalOptions = {
      title: '',
      color:'primary',
      canBackwardsSelected : true,
      from : new Date(2017,1, 1),
      defaultDate : defDate,
      defaultScrollTo : defDate
    };

  let myCalendar =  await this.modalCtrl.create({
    component: CalendarModal,
    componentProps: { options }
  });

  myCalendar.present();

  loading.dismiss();
 
  const event: any = await myCalendar.onDidDismiss();
    const date: CalendarResult = event.data;
    this.current_data =  this.datepipe.transform(date.dateObj, 'yyyy-MM-dd');
  }
//#endregion

  public IDDipendente: number = 0;
  public ListDipendenti: any = [];
  public Commesse: any = [];
  public CommesseFasi: any = [];
  public CommesseClienti: any =[];
  public TipiRilevazioni: any = [];
  public TipiRilevazioniComplessivi: any = [];
  public StatiRilevazioni: any = [];
  public RilevazioniApertePrecedentiCommesse: any = [];
  public RilevazioniAperteOggiCommesse: any = [];

  public selTempo: any=[];
  public selIDCommessa;
  public selCommessa;
  public selIDTipoRilevamento;
  public selIDStatoRilevazione;
  public showOrdiniPrecedenti: boolean = true;
  public mySearch: string="";

  constructor(public datepipe: DatePipe, public modalCtrl: ModalController,  public alertCtrl: AlertController, public toastController: ToastController, public httpClient: HttpClient, private router: Router, private _mysrv: RendicontazioneService, private navCtrl: NavController, public _myglobal: GlobalService, public loadingController: LoadingController) {
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    {
      this.navCtrl.navigateRoot('/login');
    }
    console.log('constructor');
    //this.current_data = '2021-03-25';
  }

  ngOnInit() {
    this._loadDipendenti();
    this._loadTStatiRilevazioni();
    this._loadTipoRilevazioni();
    this._loadCommesse();
  }
  
  ionViewDidEnter() {
    this._myglobal.GETUserData();
    this._loadDipendenti();
    this._loadTStatiRilevazioni();
    this._loadTipoRilevazioni();
    this._loadCommesse();
    this._loadRilevazioniApertePrecedentiCommesse();
    this._loadRilevazioniAperteOggiCommesse();
  }

  doRefresh(event) {
   this._Refresh();
    event.target.complete();
   }

   _Refresh(){
    this._loadCommesse();
    this._loadRilevazioniApertePrecedentiCommesse();
    this._loadRilevazioniAperteOggiCommesse();
   }

 

  async _loadCommesse(){
    //CARICA LE COMMESSE
    this.CommesseFasi=[];
    const loading = await this.loadingController.create({
      message: 'Caricamento Commesse...',
    });
    await loading.present();
    this._mysrv.Get_Commesse(this.IDDipendente, -9999, null).subscribe((data : any) => {
      this.Commesse = data;
      console.log('LOAD COMM: ' + JSON.stringify(this.Commesse));
      if (this.selIDCommessa>0) {
        console.log('LOAD COMM: selIDCommessa ' + this.selIDCommessa);
        this.Commesse.forEach(c => {
          if (c.IDCommessa == this.selIDCommessa) {
            this.selCommessa=c;
            this._loadCommesseFasi(this.selCommessa);
          }
        });
      }
      //console.log('LOAD COMMESSE');
      //console.log (this.Commesse);
      loading.dismiss();  
    }, error => {
      loading.dismiss();  
      console.log(error);
    });
  }

  
  async _loadCommesseFasi(x){
    console.log("LOAD FASIIII");
    console.log(JSON.stringify(x));
    //CARICA LE COMMESSE
    const loading = await this.loadingController.create({
      message: 'Caricamento Commesse...',
    });
    await loading.present();
    this._mysrv.Get_CommesseFasi(this.current_data, this.IDDipendente, x.IDCommessa, 0, null).subscribe((data : any) => {
      this.CommesseFasi = data;
      console.log('LOAD FASI');
      console.log (this.CommesseFasi);
      loading.dismiss();  
    }, error => {
      loading.dismiss();  
      console.log(error);
    });
  }


  async _loadTipoRilevazioni(){
    //CARICO LE TIPOLOGIE DI RILEVAZIONE (0= PER SELECT IN AVVIO TEMPO;  1= PER ARTICOLI SPECIALI (IMB e ASS))
    this._mysrv.ListTipoRilevazioni("0").subscribe((data : any) => {
        this.TipiRilevazioni = data;
    }, error => {
      console.log(error);
    });
    this._mysrv.ListTipoRilevazioni("1").subscribe((data : any) => {
      this.TipiRilevazioniComplessivi = data;
  }, error => {
    console.log(error);
  });
  }

  
  async _loadTStatiRilevazioni(){
    //CARICO GLI STATI RILEVAZIONI MOSTRATO NELL ASELECT SULLO STOP RILEVAZIONE (Sospensione e Completato)
    this._mysrv.ListStatiRilevazioni().subscribe((data : any) => {
        this.StatiRilevazioni = data;
    }, error => {
      console.log(error);
    });
  }

    
  

  async _loadDipendenti(){
    //CARICO I DIPENDENTI
    let IDDipendenteLogged;
    IDDipendenteLogged = this._myglobal.GETIDDipendente();
    if (IDDipendenteLogged>0 && this._myglobal.GETUserType() == "ADMIN") {
      IDDipendenteLogged = -9999;
    }
    this._mysrv.Get_Anagrafiche("DIP", IDDipendenteLogged).subscribe((data : any) => {
        this.ListDipendenti = data;
        this.IDDipendente = parseInt(localStorage.getItem("IDDipendente"));
        this.cmbDip.disabled = true;
        //this.IDDipendente=-9999;
        //console.log('LOAD DIPENDENTI');
        //console.log (this.ListDipendenti);
    }, error => {
      console.log(error);
    });

  }
  
  async _loadRilevazioniApertePrecedentiCommesse(){
    //CARICA LE COMMESSE APERTE PRECEDENTI ALLA DATA SELEZIONATA
    // if(this.IDDipendente==null || this.IDDipendente<=0) {this.RilevazioniApertePrecedenti =[];return;}
    this._mysrv.ListRilevazioniApertePrecedentiCommesse(this.current_data, this.IDDipendente).subscribe((data : any) => {
      this.RilevazioniApertePrecedentiCommesse = data;
      console.log('RILEVAZIONI APERTE PRECEDENTI CoMMESSE: ' + this.IDDipendente);
      console.log(this.RilevazioniApertePrecedentiCommesse);
    }, error => {
      console.log(error);
    });
  }
  async _loadRilevazioniAperteOggiCommesse(){
    //CARICA LE COMMESSE APERTE PRECEDENTI ALLA DATA SELEZIONATA
    // if(this.IDDipendente==null || this.IDDipendente<=0) {this.RilevazioniApertePrecedenti =[];return;}
    this._mysrv.ListRilevazioniAperteOggiCommesse(this.current_data, this.IDDipendente).subscribe((data : any) => {
      this.RilevazioniAperteOggiCommesse = data;
      console.log('RILEVAZIONI APERTE OGGI CoMMESSE: ' + this.IDDipendente);
      console.log(this.RilevazioniAperteOggiCommesse);
    }, error => {
      console.log(error);
    });
  }

private enableStart: boolean=false;
private enableStop: boolean=false;

  async StartComessa(x) {
     //CHIAMO L'INSERIMENTO DELLA RILEVAZIONE TEMPI
     console.log('START_COMM');
     console.log(JSON.stringify(x));
     let mydata: string =   this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    if (this.current_data != this.datepipe.transform(new Date(), 'yyyy-MM-dd')) {
      //SE INIZIO UNO START IN DATA DIVERSA DA OGGI CHIEDO CONFERMA
      let alert = await this.alertCtrl.create({
        header: 'Next App',
        message: "Attenzione! La commessa scelta non è pianificato per oggi.<br/> Procedere?",
        buttons: [
          { text: 'Annulla', role: 'cancel', handler: () => {
            this.selIDTipoRilevamento=null;
            this.cmbTipoRilevazioneStart.value=null;
            return;
           }
          },
          {
            text: 'Conferma',
            handler: () => {
              this.StartCommessaSave(x, mydata);  
            }
          }
        ]
      });
      await alert.present();
      } else {
         this.StartCommessaSave(x, mydata)
      }
    }

    StartCommessaSave(x, mydata){
      this._mysrv.INS_COMM_RilevazioneTempi(x, mydata).subscribe((data : any) => {
           this.presentToast('Lavorazione iniziata!','primary');
           console.log('IDCommessa:' + x.IDCommessa);
           console.log('selIDCommessa:' + this.selIDCommessa);
           this._loadCommesseFasi(x); 
       }, error => {
         console.log(error);
         this.presentAlert('Errore nel salvataggio :' + error);
       });
    }

 StopCommessa_OpenSelect(x)  {
    this.enableStop=true;
    console.log(x);
    this.selTempo = x;
    this.cmbStatoRilevazioneCommessa.open();
   }

  async StopCommessa() {
    if (!this.enableStop) {
      this.enableStop=false;
      this.selIDTipoRilevamento=null;
      this.cmbStatoRilevazioneCommessa.value=null;
      return;
    }
   this.enableStop=false;

    let x: any =[];
    x= this.selTempo;
    this.selIDTipoRilevamento=null;
    x.IDStatoRilevazione = this.selIDStatoRilevazione;
    
    if (!(x.IDStatoRilevazione != null && x.IDStatoRilevazione > 0)) {
       this.presentAlert('Selezionare uno Stato di rilevamento!');
       return;
     }

    let alertCtrl = await this.alertCtrl.create({
      header : 'Nota',
      inputs :[ { type: 'textarea', value: '', placeholder: 'Nota', } ],
      buttons: [ { text: 'OK', handler: data => {
                x.Descrizione = data[0]; 
                
        

    let mydata: string =  this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    ////CHIAMO L'INSERIMENTO IN ATTIVITA E LA CHIUSURA DELLA RILEVAZIONE TEMPI
    this._mysrv.INS_COMM_Attivita(x.TempoStart, mydata, x.Descrizione, x.IDCommessa, x.IDFase).subscribe((data : any) => {
      if (data != null && data.length>0) {
        x.IDAttivitaRendicontazione = data[0].IDAttivita;
        console.log(x.IDAttivitaRendicontazione);
        if (x.IDAttivitaRendicontazione>0) {
              this._mysrv.UPD_RilevazioneTempi(x, mydata).subscribe((data : any) => {
                // this.presentAlert('Lavorazione conclusa!');
                this.presentToast('Lavorazione conclusa!','primary');
                this._Refresh(); 
                this.cmbStatoRilevazioneCommessa.value=null;
              }, error => {
                console.log(error);
              });
      }}
    }, error => {
      console.log(error);
      this.presentAlert('Errore nel salvataggio :' + error);
    });
  } },]})
  alertCtrl.present();
  }

 


   async DEL_Rilevazione(x) {
     //ELIMINAZIONE DELLA RILEVAZIONE TEMPI
    let alert = await this.alertCtrl.create({
      header: 'Next App',
      message: "Eliminare questa rilevazione?",
      buttons: [
        { text: 'Annulla', role: 'cancel', handler: () => { }
        },
        {
          text: 'Conferma',
          handler: () => {
             this._mysrv.DEL_RilevazioneTempi(x.IDRilevazioneTempi).subscribe((data : any) => {
              console.log('IDAttivita : ' + x.IDAttivitaRendicontazione);
            //   if (x.IDAttivitaRendicontazione!=null && x.IDAttivitaRendicontazione>0) {
            //   this._mysrv.DEL_Attivita(x.IDAttivitaRendicontazione).subscribe((data : any) => {
            //   }, error => {
            //     console.log(error);
            //   });
            // }
              this._Refresh(); 
              // this.presentAlert('Lavorazione eliminata!');
              this.presentToast('Lavorazione eliminata!','danger');
            }, error => {
              console.log(error);
            });
          }
        }
      ]
    });
    await alert.present();
  }

  slideChanged() {
    let currentIndex = this.slides.getActiveIndex();
    console.log('Current index is', currentIndex);
  }

  selectCommessa(o, i) {
    //CARICO LE FASI 
    this.slides.slideTo(i, 500);
    console.log('INDEX:' + i)
    this.showOrdiniPrecedenti=false;
    this.selIDCommessa=o.IDCommessa;
    this.selCommessa=o;
    this._loadCommesseFasi(o);
  }

  async presentToast(msg, mycolor) {
    const toast = await this.toastController.create({
      message: msg,
      color: mycolor,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }

   async presentAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Next App',
      subHeader: '',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }


  async note(x){
    let alertCtrl = await this.alertCtrl.create({
      header : 'Nota',
      inputs :[ { type: 'textarea', value: x, placeholder: 'Nota', } ],
      buttons: [ { text: 'OK', handler: data => {
                x = data[0]; 

          } },]})
    alertCtrl.present();
  }



}
