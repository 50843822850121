import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, NavController } from '@ionic/angular';
import { DocumentifeService } from '../services/documentife.service';
import { GlobalService } from '../services/global.service'; 
import { ModalController } from '@ionic/angular';
import {  CalendarModal,  CalendarModalOptions,  DayConfig,  CalendarResult} from 'ion2-calendar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-documentife',
  templateUrl: './documentife.page.html',
  styleUrls: ['./documentife.page.scss'],
})
export class DocumentifePage implements OnInit {
  public IDGruppoSistema: string="INV";
  public dataFine: string =   this.datepipe.transform(new Date() , 'yyyy-MM-dd'); // new Date().toISOString();
  public dataInizio: string = this.datepipe.transform(new Date(new Date().getFullYear(), new Date().getMonth() -1, 1), 'yyyy-MM-dd'); 
  public mySearch: string ="";
  public Documenti: any = [];
  public Gruppi: any = [];
  public DocumentiStati: any = [];
  public myDownloadLink: string = "";


  async openCalendar(datetoupdate) {
    const loading = await this.loadingController.create({
      message: 'Caricamento...',
    });
    await loading.present();
    let defDate;
    if (datetoupdate=='start') {
      defDate =  this.dataInizio;
    }
    if (datetoupdate=='end') {
      defDate =  this.dataFine;
    }
    
    const options: CalendarModalOptions = {
      title: '',
      color:'primary',
      canBackwardsSelected : true,
      from : new Date(2017,1, 1),
      defaultDate : defDate,
      defaultScrollTo : defDate
    };

  let myCalendar =  await this.modalCtrl.create({
    component: CalendarModal,
    componentProps: { options }
  });

  myCalendar.present();

  loading.dismiss();

  const event: any = await myCalendar.onDidDismiss();
    const date: CalendarResult = event.data;
    if (datetoupdate=='start') {
      this.dataInizio =  this.datepipe.transform(date.dateObj, 'yyyy-MM-dd');
      console.log( this.dataInizio);
    }
    if (datetoupdate=='end') {
      this.dataFine = this.datepipe.transform(date.dateObj, 'yyyy-MM-dd');
      console.log( this.dataFine);
    }
    
}

  

  constructor(public datepipe: DatePipe, public modalCtrl: ModalController, public httpClient: HttpClient, private router: Router, private _mysrv: DocumentifeService, private navCtrl: NavController, public _myglobal: GlobalService, public loadingController: LoadingController) {
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    //if (this._myglobal.IDLicenzaAnagrafica==null || this._myglobal.IDLicenzaAnagrafica<=0)
    {
      this.navCtrl.navigateRoot('/login');
    }
  }

  async downloadXML(nomedoc) {
    const loading = await this.loadingController.create({
      message: 'Caricamento Documenti...',
    });
    await loading.present();
    this._mysrv.Download_Documento(nomedoc).subscribe((b64 : any) => {
      console.log(b64);
      console.log(nomedoc);
      this._mysrv.StoreFile(nomedoc+'.xml', b64, true).subscribe((data : any) => {
        console.log(data);
        this.myDownloadLink = data[0].Result;  
        loading.dismiss();  
        console.log(this.myDownloadLink); 
        this._mysrv.InsertFEDownloaded(nomedoc).subscribe((data : any) => { 
          
        });
        this.downloadStatoFE();
        var anchor = document.createElement('a');
        anchor.href = this.myDownloadLink;
        anchor.target = '_blank';
        anchor.download = nomedoc+'.zip' ;
        anchor.click();
      }, error => {
        console.log(error);
        loading.dismiss();
      });
    }, error => {
      console.log(error);
      loading.dismiss();
    });
  }

  
  downloadStatoFE() {
    this._mysrv.GetFEDownloaded().subscribe((data : any) => {
      this.DocumentiStati = data;
      this.DocumentiStati.forEach((item,index,obj) => {
        this.Documenti.forEach((item2,index2,obj2) => {
            if (item2.NomeDoc==item.NomeDoc) {
              item2.AppDownloaded = true;
              item2.AppDataDownload = item.Downloaded;
            }
        });
      });
      //console.log("POST PROCESS: ");
      //console.log(this.Documenti);
    }, error => {
      console.log(error);
    });
 }


  navigateXml(){
    this.router.navigate(['/documenti-xml']);
  }
  
  async loadData(){
    console.log(this.dataInizio);
    if (this.IDGruppoSistema=="INV") {
      this._mysrv.loadINVIATE(this.dataInizio, this.dataFine);
      this._mysrv.Evento.subscribe(data => {
        this.Documenti=data; 
        this.downloadStatoFE();

        //console.log(data);
      });
    }
    if (this.IDGruppoSistema=="RIC") {
      this._mysrv.loadRICEVUTE(this.dataInizio, this.dataFine);
      this._mysrv.Evento.subscribe(data => {
        this.Documenti=data; 
        this.downloadStatoFE();

      //  console.log(data);
      });
    }
    if (this.IDGruppoSistema=="VA") {
      this._mysrv.loadDAVALIDARE(this.dataInizio, this.dataFine);
      this._mysrv.Evento.subscribe(data => {
        this.Documenti=data; 
        this.downloadStatoFE();

       // console.log(data);
      });
    }
    if (this.IDGruppoSistema=="VN") {
      this._mysrv.loadVALIDAZIONENEGATA(this.dataInizio, this.dataFine);
      this._mysrv.Evento.subscribe(data => {
        this.Documenti=data; 
        this.downloadStatoFE();

      //  console.log(data);
      });
    }
  }

  public doRefresh(event) {
    this.loadData();    
    event.target.complete();
  }

  navigate(id){
    //this.navCtrl.navigateForward(['/tabs/documentiedit', id]);
  }
 
  ngOnInit(){  
    this.loadData();
  }
  ionViewDidEnter() {
    this._myglobal.GETUserData();
    this.loadData();
  }
  
  async downloadHTML(nomedoc) {
    const loading = await this.loadingController.create({
      message: 'Apertura Documento...',
    });
    await loading.present();
    this._mysrv.Download_Documento(nomedoc).subscribe((b64 : any) => {
      console.log(b64);
      console.log(nomedoc);
      this._mysrv.StoreFileHTML(nomedoc+'.xml', b64, false).subscribe((data : any) => {
        console.log(data);
        this.myDownloadLink = data[0].Result;  
        loading.dismiss();  
        console.log(this.myDownloadLink); 
        window.open(data[0].Result, '_blank')
        // var anchor = document.createElement('a');
        // anchor.href = this.myDownloadLink;
        // anchor.target = '_blank';
        // anchor.download = nomedoc+'.html' ;
        // anchor.click();
      }, error => {
        console.log(error);
        loading.dismiss();
      });
    }, error => {
      console.log(error);
      loading.dismiss();
    });
  }


  async Valida(nomedoc) {
    const loading = await this.loadingController.create({
      message: 'Approvazione Documento...',
    });
    await loading.present();
    this._mysrv.Valida_Documento(nomedoc).subscribe(data => {

      loading.dismiss();
    }, error => {
      console.log(error);
      loading.dismiss();
    });
  }

  

  async ValidazioneNegata(nomedoc) {
    const loading = await this.loadingController.create({
      message: 'Negazione Documento...',
    });
    await loading.present();
    this._mysrv.ValidazioneNegata_Documento(nomedoc).subscribe(data => {

      loading.dismiss();
    }, error => {
      console.log(error);
      loading.dismiss();
    });
  }


}
