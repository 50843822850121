import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AnagraficheService {
  @Output() Evento = new EventEmitter;

  constructor(private httpClient: HttpClient,private _global: GlobalService, public loadingController: LoadingController) { }

  async loadAnagrafiche(IDGruppoSistema, Page, Limit, Search, ShowLoading) {
    this._global.GETendpointCURRENT();
    const loading = await this.loadingController.create({
      message: 'Carcamento Anagrafiche...',
    });
    if (ShowLoading) {
      await loading.present();
    }
    console.log('serv:'+this._global.CodiceUnita);
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDGruppoSistema": IDGruppoSistema,
      "Limit" : Limit,
      "Page" : Page,
      "Search" : Search
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL + this._global.GETLocal() + "GetAnagrafiche", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
        if (ShowLoading) { loading.dismiss(); }
       }, error => {
        console.log(error);
        if (ShowLoading) {  loading.dismiss(); }
      });
  }
  
   
  loadAnagraficheSearch(IDGruppoSistema, Page, Limit, Search) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDGruppoSistema": IDGruppoSistema,
      "Limit" : Limit,
      "Page" : Page,
      "Search" : Search
  }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "GetAnagrafiche", postData, headers) 
  }
   
  loadAnagrafica(IDAnagrafica) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDAnagrafica" : IDAnagrafica
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL  + this._global.GETLocal() + "GetAnagrafiche", postData, headers) 
  }



}
