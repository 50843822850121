import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-content',
  template: `<ion-toolbar>
              <ion-searchbar [animated]="true" (ionInput)="handleSearch($event)"></ion-searchbar>
               
            </ion-toolbar>

            <ion-content>
              <ion-list>
                <ion-item *ngFor="let item of resultItems" (click)="handleSelectItem(item)">
                  <ion-label>{{ item[optionLabel] }}</ion-label>
                </ion-item>
              </ion-list>
            </ion-content>`,
            styles: [`
            ion-item:hover {
              --background: #f0f0f0; /* Change to the desired background color */
              cursor: pointer;
            }
          `]
})
export class ModalContent implements OnInit {

  // @Input() disabled: boolean = false;
  // @Input() btnLabel: string = '';
  // @Input() btnPlaceholder: string = '';
  @Input() btnCancelLabel: string = 'Annulla';
  @Input() items: any[] = [];
  @Input() resultItems: any[] = [];
  @Input() optionLabel: string = 'label';
  @Input() optionValue: string = 'value';
  @Input() selectedValue?: any;
  @Output() selectedValueChange = new EventEmitter<any>();

  public selectedItem?: any;

  public showPopover: boolean = false;
  //#endregion

  constructor(public modalController: ModalController) {

  }

  ngOnInit() {
    this.handleSlectedItem();
  }

  handleSlectedItem() {
    if (this.selectedValue) {
      this.selectedItem = this.items.find(i => i[this.optionValue] === this.selectedValue);
      console.log('select item : ', this.selectedItem);
    }
  }

  //#region //* Methods *\\
  handleSelectItem(item: any) {
    //if (item[this.optionValue] !== this.selectedValue) {
      this.selectedValue = item[this.optionValue];
      this.selectedValueChange.emit(item[this.optionValue]);
      this.handleSlectedItem();
      this.modalController.dismiss({
        selectedValue : this.selectedValue
      });
    //}
    
  }
  
  handleSearch(ev: any) {
    const searchText = ev.target.value.toLowerCase();
    this.resultItems = this.items.filter(i => i[this.optionLabel].toLowerCase().includes(searchText));
  }
}

@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss'],
})
export class DropdownSearchComponent implements OnInit {

  //#region //* States *\\
  // @ViewChild('modal') modal!: ;
  @Input() disabled: boolean = false;
  @Input() btnLabel: string = '';
  @Input() btnPlaceholder: string = '';
  @Input() btnCancelLabel: string = 'Annulla';
  @Input() items: any[] = [];
  @Input() optionLabel: string = 'label';
  @Input() optionValue: string = 'value';
  @Input() selectedValue?: any;
  @Output() selectedValueChange = new EventEmitter<any>();

  public selectedItem?: any;
  public resultItems: any[] = [];

  public showPopover: boolean = false;
  //#endregion


  constructor(public modalController: ModalController) {

  }

  ngOnInit() {
    this.handleSlectedItem();
  }

  handleSlectedItem() {
    if (this.selectedValue) {
      this.selectedItem = this.items.find(i => i[this.optionValue] === this.selectedValue);
    }
  }

  //#region //* Methods *\\
  handleSelectItem(item: any) {
    if (item[this.optionValue] !== this.selectedValue) {
      this.selectedValue = item[this.optionValue];
      this.selectedValueChange.emit(item[this.optionValue]);
      this.handleSlectedItem();
    }
    this.modalController.dismiss({
      'dismissed' : true
    });
  }

  async openPopover() {
    this.resultItems = [...this.items];
    //this.showPopover = true;
    const modal = await this.modalController.create({
      component : ModalContent,
      cssClass : 'dropdownSearch',
      componentProps: { 
        // btnLabel : this.btnLabel, 
        btnCancelLabel : this.btnCancelLabel, 
        optionLabel: this.optionLabel,
        optionValue: this.optionValue,
        selectedValue : this.selectedValue,
        items : this.items,
        resultItems : this.resultItems

       }
    });
    await modal.present();
    const retValue = await modal.onWillDismiss();
    const item = this.items.find(i => i[this.optionValue] === retValue.data.selectedValue);
    console.log(retValue)
    this.selectedValue = item[this.optionValue];
    this.selectedValueChange.emit(item[this.optionValue]);
    this.handleSlectedItem();

  }

  handleSearch(ev: any) {
    const searchText = ev.target.value.toLowerCase();
    this.resultItems = this.items.filter(i => i[this.optionLabel].toLowerCase().includes(searchText));
  }
  //#endregion

}
