import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class RendicontazioneService {
   
  constructor(private httpClient: HttpClient,private _global: GlobalService, public loadingController: LoadingController) {   }
 

  ListOrdiniLavoroPianificati(DataSearch, IDDipendente, DataSearchType, ShowOnlyStartedActivities, functionname) {
    this._global.GETendpointCURRENT();
    let postData = {
      "DataSearch" : DataSearch,
      "IDDipendente" : IDDipendente,
      "DataSearchType" : DataSearchType,
      "ShowOnlyStartedActivities" : ShowOnlyStartedActivities,
    }
    //console.log('ALL ORL');
    console.log(this._global.myURL + ' >>> ' + functionname + ' >>> ' + JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXTGetOrdiniLavoroPianificati", postData, headers) 
  }

  ListRilevazioniApertePrecedentiCommesse(DataSearch, IDDipendente) {
    this._global.GETendpointCURRENT();
    let postData = {
      "DataSearch" : DataSearch,
      "IDDipendente" : IDDipendente,
      "AllSubFasi" : 1,
    }
    //console.log('ALL ORL');
    console.log(this._global.myURL + ' >>> ' + JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_RilevazioniApertePrecedentiCommesse", postData, headers) 
  }
  ListRilevazioniAperteOggiCommesse(DataSearch, IDDipendente) {
    this._global.GETendpointCURRENT();
    let postData = {
      "DataSearch" : DataSearch,
      "IDDipendente" : IDDipendente,
      "AllSubFasi" : 1,
      "DataSearchCondition" : "=",
    }
    //console.log('ALL ORL');
    console.log(this._global.myURL + ' >>> ' + JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_RilevazioniApertePrecedentiCommesse", postData, headers) 
  }

  ListTipoRilevazioni(IsComplessivo: string) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IsComplessivo" : IsComplessivo,
    }
    //console.log('TIPI RILEVAZ');
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXTGetTipoRilevazioni", postData, headers) 
  }

  ListStatiRilevazioni() {
    this._global.GETendpointCURRENT();
    let postData;
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXTGetStatiRilevazioni", postData, headers) 
  }

  INS_RilevazioneTempi(x, DataStart, IDCommessa, IDFase) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDOrdineCliente" : x.IDDocumento,
      "IDOrdineProduzione" : x.IDDocumentoORL,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "IDTipoRilevamento" : x.IDTipoRilevamento,
      "TempoStart" : DataStart, 
      "IDCommessa" : IDCommessa,
      "IDFase" : IDFase,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_INS_RilevazioneTempi", postData, headers) 
  }

  INS_COMM_RilevazioneTempi(x, DataStart) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDOrdineCliente" : 0,
      "IDOrdineProduzione" : 0,
      "IDCommessa" : x.IDCommessa,
      "IDFase" : x.IDFase,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "TempoStart" : DataStart, 
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_INS_RilevazioneTempi", postData, headers) 
  }

  UPD_RilevazioneTempi(x, TempoEnd) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDRilevazioneTempi" : x.IDRilevazioneTempi,
      "IDOrdineCliente" : x.IDOrdineCliente,
      "IDOrdineProduzione" : x.IDOrdineProduzione,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "IDTipoRilevamento" : x.IDTipoRilevamento,
      "TempoStart" : x.TempoStart,
      "TempoStop" :  TempoEnd,
      "IDAttivitaRendicontazione" : x.IDAttivitaRendicontazione,
      "IDStatoRilevazione": x.IDStatoRilevazione,
      "IDCommessa" : x.IDCommessa,
      "IDFase" : x.IDFase,
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_UPD_RilevazioneTempi", postData, headers) 
  }

  DEL_RilevazioneTempi(IDRilevazioneTempi) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDRilevazioneTempi" : IDRilevazioneTempi
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_DEL_RilevazioneTempi", postData, headers) 
  }
  
  DEL_Attivita(IDAttivita) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDAttivita" : IDAttivita
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_DEL_Attivita", postData, headers) 
  }
  
   INS_Attivita(TempoStart, TempoEnd, Descrizione, IDCommessa, IDFase) {
    this._global.GETendpointCURRENT();
    let postData = {
      "CodiceCausale" : "ORD",
      "IDOggettoGeneratore" : IDCommessa,
      "IDCommessaFase" : IDFase,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "DataOraInizio" :  TempoStart,
      "DataOraFine" :  TempoEnd,
      "Descrizione" : Descrizione,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_INS_OR_UPD_Attivita", postData, headers) 
  }

  INS_COMM_Attivita(TempoStart, TempoEnd, Descrizione, IDCommessa, IDFase) {
    this._global.GETendpointCURRENT();
    let postData = {
      "CodiceCausale" : "ORD",
      "IDOggettoGeneratore" : IDCommessa,
      "IDCommessaFase" : IDFase,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "DataOraInizio" :  TempoStart,
      "DataOraFine" :  TempoEnd,
      "Descrizione" : Descrizione,
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_INS_OR_UPD_Attivita", postData, headers) 
  }

  
  UPD_Attivita(x) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDAttivita" : x.IDAttivita,
      "IDAttivitaCausale" : x.IDAttivitaCausale,
      "IDOggettoOperatore" : x.IDOggettoOperatore,
      "DataOraInizio" :  x.DataOraInizio,
      "DataOraFine" :  x.DataOraFine,
      "Qta" : x.Qta,
      "Descrizione" : x.Descrizione,
      "IDCommessaFase" : x.IDCommessaFase,
      "CodiceCausale" : x.CodiceCausale,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "NotAssigned" : x.NotAssigned
    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_INS_OR_UPD_Attivita", postData, headers) 
  }

  Get_Attivita(IDDipendente, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDOggettoOperatore" : IDDipendente,
      "DataOraInizio" :  Data,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_Attivita", postData, headers) 
  }

  Get_Attivita_ByIDAttivita(IDAttivita) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDAttivita" : IDAttivita 
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_Attivita", postData, headers) 
  }

  Get_Attivita_Mese_Sum(IDDipendente, Anno, Mese) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDOggettoOperatore" : IDDipendente,
      "Anno" :  Anno,
      "Mese" : Mese
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_Attivita_Mese_Sum", postData, headers) 
  }

  

  Get_Anagrafiche(IDGruppoSistema, IDAnagrafica) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDGruppoSistema" : IDGruppoSistema,
      "IDAnagrafica" : IDAnagrafica,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GetAnagrafiche", postData, headers) 
  }

  Get_Commesse(IDDipendente, IDCliente, ShowCommesseNotAssigned) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDDipendente" : IDDipendente,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "IDCliente" : IDCliente,
      "Stato" : 'APE',
      "ShowCommesseNotAssigned" : ShowCommesseNotAssigned
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_Commesse", postData, headers) 
  }
  
  Get_CommesseFasi(DataSearch, IDDipendente, IDCommessa, AllSubFasi, ShowCommesseNotAssigned) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDCommessa" : IDCommessa,
      "IDDipendente" : IDDipendente,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "Stato" : 'APE',
      "DataSearch" : DataSearch,
      "AllSubFasi" : AllSubFasi,
      "ShowCommesseNotAssigned" : ShowCommesseNotAssigned

    }
    console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_CommesseFasi", postData, headers) 
  }

  Get_CommesseClienti(IDDipendente, ShowCommesseNotAssigned) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDDipendente" : IDDipendente,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
      "Stato" : 'APE',
      "ShowCommesseNotAssigned" : ShowCommesseNotAssigned
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_Commesse_Clienti", postData, headers) 
  }

  SUM_Attivita_Per_Tipo(IDDipendente, Data) {
    let postData = {
      "IDOggettoOperatore" : IDDipendente,
      "DataOraInizio" :  Data,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_SUM_Tipo_Attivita", postData, headers) 
  }

  SUM_Attivita_Totale(IDDipendente, Data) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDOggettoOperatore" : IDDipendente,
      "DataOraInizio" :  Data,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_SUM_Attivita", postData, headers) 
  }

  SUM_Attivita_Totale_Mese(IDDipendente, Anno, Mese) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDOggettoOperatore" : IDDipendente,
      "Anno" :  Anno,
      "Mese" :  Mese,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_SUM_Attivita", postData, headers) 
  }

   Get_CommessaFaseDiProduzione(IDDipendente) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDDipendente" : IDDipendente,
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_CommessaFaseProduzione", postData, headers) 
  }

  CheckRilevazioneTempi(IDOrdineCliente, IDOrdineProduzione) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDOrdineCliente" : IDOrdineCliente,
      "IDOrdineProduzione" : IDOrdineProduzione,
      "IDUtente" : localStorage.getItem("IDUtenteMaster"),
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_CheckRilevazioneTempi", postData, headers) 
  }


  Get_AttivitaTipologie() {
    this._global.GETendpointCURRENT();
    let postData = {}
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_AttivitaTipologia", postData, headers) 
  }

  Get_AttivitaPianificate(IDCommessaFase) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDCommessaFase" : IDCommessaFase
    }
    //console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + "NEXT_GET_AttivitaPianificate", postData, headers) 
  }


}
