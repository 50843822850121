import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
    //loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tab1',
    loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule)
  },
  {
    path: 'tab2',
    loadChildren: () => import('./tab2/tab2.module').then(m => m.Tab2PageModule)
  },
  {
    path: 'tab3',
    loadChildren: () => import('./tab3/tab3.module').then(m => m.Tab3PageModule)
  },
  {
    path: 'documentiedit',
    loadChildren: () => import('./documentiedit/documentiedit.module').then( m => m.DocumentieditPageModule)
  },
  {
    path: 'anagraficheedit',
    loadChildren: () => import('./anagraficheedit/anagraficheedit.module').then( m => m.AnagraficheeditPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'aziende',
    loadChildren: () => import('./aziende/aziende.module').then( m => m.AziendePageModule)
  },
  {
    path: 'profilo',
    loadChildren: () => import('./profilo/profilo.module').then( m => m.ProfiloPageModule)
  },
  {
    path: 'documenti-xml',
    loadChildren: () => import('./documenti-xml/documenti-xml.module').then( m => m.DocumentiXmlPageModule)
  },
  {
    path: 'documentife',
    loadChildren: () => import('./documentife/documentife.module').then( m => m.DocumentifePageModule)
  },
  {
    path: 'downloads',
    loadChildren: () => import('./downloads/downloads.module').then( m => m.DownloadsPageModule)
  },
  {
    path: 'articoli-edit',
    loadChildren: () => import('./articoli-edit/articoli-edit.module').then( m => m.ArticoliEditPageModule)
  },
  {
    path: 'rendicontazioni',
    loadChildren: () => import('./rendicontazioni/rendicontazioni.module').then( m => m.RendicontazioniPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'produzione-articoli-queue',
    loadChildren: () => import('./produzione-articoli-queue/produzione-articoli-queue.module').then( m => m.ProduzioneArticoliQueuePageModule)
  },
  {
    path: 'articolo-modal',
    loadChildren: () => import('./modals/articolo-modal/articolo-modal.module').then( m => m.ArticoloModalPageModule)
  },
  {
    path: 'bncmagazzinorighe',
    loadChildren: () => import('./bncmagazzinorighe/bncmagazzinorighe.module').then( m => m.BncmagazzinorighePageModule)
  },
  {
    path: 'bncmovimentazionicassa',
    loadChildren: () => import('./bncmovimentazionicassa/bncmovimentazionicassa.module').then( m => m.BncmovimentazionicassaPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
