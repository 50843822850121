import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { LoadingController } from '@ionic/angular';
import { NotExpr } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class ArticoliService {
  @Output() Evento = new EventEmitter;

  constructor(private httpClient: HttpClient,private _global: GlobalService, public loadingController: LoadingController) { }

  async loadArticoli(Page, Limit, Search, ShowLoading) {
    this._global.GETendpointCURRENT();
    const loading = await this.loadingController.create({
      message: 'Carcamento Articoli...',
    });
    if (ShowLoading) {
      await loading.present();
    }
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "Limit" : Limit,
      "Page" : Page,
      "Search" : Search
    }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL + this._global.GETLocal() + "getArticoli", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
        if (ShowLoading) { loading.dismiss(); }
       }, error => {
        console.log(error);
        if (ShowLoading) { loading.dismiss(); }
      });
  }

  
  loadArticoliSearch(Page, Limit, Search) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "Limit" : Limit,
      "Page" : Page,
      "Search" : Search
    }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "getArticoli", postData, headers) 
  }

  loadArticolo(IDArticolo) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDArticolo" : IDArticolo 
    }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "getArticoli", postData, headers) 
  }
  

  loadMagazzini() {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
    }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "GET_Magazzini", postData, headers) 
  }
  
  loadMovimenti(IDMagazzino, IDArticolo) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDMagazzino" : IDMagazzino,
      "IDArticolo" : IDArticolo,
    }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "GET_Movimenti", postData, headers) 
  }

  loadMovimentiToSync(IDMagazzino, IDArticolo) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDMagazzino" : IDMagazzino,
      "IDArticolo" : IDArticolo,
      "OnlyToSync" : 1,
    }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "GET_MovimentiToSync", postData, headers) 
  }

  loadCausali() {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDGruppoSistema" : "MAG",
    }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "GET_Causali", postData, headers) 
  }

  loadCausale(IDAttivitaCausale) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDAttivitaCausale" :  IDAttivitaCausale,
    }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "GET_Causali", postData, headers) 
  }

  INS_Movimenti(Data, IDMagazzino, IDMagazzinoDest, IDArticolo, IDTipoMovimento, Quantita, Note) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDUtente" : this._global.GETIDUtente(),
      "Data" : Data,
      "IDMagazzino" : IDMagazzino,
      "IDMagazzinoDest" : IDMagazzinoDest,
      "IDArticolo" : IDArticolo,
      "IDTipoMovimento" : IDTipoMovimento,
      "Quantita" : Quantita,
      "Note" : Note,

    }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "INS_Movimenti", postData, headers) 
  }






}
