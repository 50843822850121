import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';
import { LoadingController } from '@ionic/angular';
import { InvokeFunctionExpr } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class DocumentiService {

  @Output() Evento = new EventEmitter;

  constructor(private httpClient: HttpClient,private _global: GlobalService, public loadingController: LoadingController) { }

  async loadDocumenti(IDTipoGruppo, IDGruppoSistema, DataDal, DataAl, Page, Limit, Search, ShowLoading) {
    this._global.GETendpointCURRENT();
    const loading = await this.loadingController.create({
      message: 'Carcamento Documenti...',
    });
    if (ShowLoading) {
      await loading.present();
    }
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDTipoGruppo" : IDTipoGruppo,
      "IDGruppoSistema": IDGruppoSistema,
      "DataDal": DataDal,
      "DataAl" : DataAl,
      "Limit" : Limit,
      "Page" : Page,
      "Search" : Search
  }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    this.httpClient.post(this._global.myURL + this._global.GETLocal() + "getDocumenti", postData, headers)
      .subscribe(data => {
        this.Evento.emit(data);
        if (ShowLoading) { loading.dismiss(); }
       }, error => {
        console.log(error);
        if (ShowLoading) { loading.dismiss(); }
      });
  }

  loadDocumentiSearch(IDTipoGruppo, IDGruppoSistema, DataDal, DataAl, Page, Limit, Search, ShowLoading) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDTipoGruppo" : IDTipoGruppo,
      "IDGruppoSistema": IDGruppoSistema,
      "DataDal": DataDal,
      "DataAl" : DataAl,
      "Limit" : Limit,
      "Page" : Page,
      "Search" : Search
  }
  console.log(JSON.stringify(postData));
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "getDocumenti", postData, headers) 
  }

  loadDocumento(IDDocumento) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDDocumento" : IDDocumento
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "getDocumenti", postData, headers) 
  }


  loadDocumentiRighe(IDDocumento) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDDocumento" : IDDocumento
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "getDocumentiRighe", postData, headers) 
  }

  
  loadGruppi(IDTipoGruppo) {
    this._global.GETendpointCURRENT();
    let postData = {
      "IDLicenzaAnagrafica": this._global.IDLicenzaAnagrafica,
      "CodiceUnita": this._global.CodiceUnita,
      "IDTipoGruppo" : IDTipoGruppo
  }
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL  + this._global.GETLocal() + "GetGruppiDocumenti", postData, headers) 
  }



  loadBNCMagazzinoRighe(selFilter:any, selFilterData:any) {
    this._global.GETendpointCURRENT();
    var InVendita : boolean=false;
    var ProntiPerFusione : boolean=false;
    var VisualizzaTutti : boolean=false;
    if (selFilter=='Vetrina') InVendita=true;
    if (selFilter=='Magazzino') ProntiPerFusione=true;
    if (selFilter=='Entrambi') VisualizzaTutti=true;
    
    var ScadenzaMode : string ="";
    if (selFilter=='Magazzino'){
      if (selFilterData=='Fermo Concluso') ScadenzaMode="SCAD";
      if (selFilterData=='Tutte le scadenze') ScadenzaMode="ALL";
    }
    
    let postData = {
      "PIVA": this._global.GETPIVA(),
      "CodiceUnita": this._global.GETCodiceUnita(),
      "InVendita" : InVendita,
      "ProntiPerFusione" :ProntiPerFusione,
      "VisualizzaTutti" : VisualizzaTutti,
      "ScadenzaMode" : ScadenzaMode
  }
  console.log(postData)
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "BNC_MagazzinoRighe", postData, headers) 
  }

  loadBNCMagazzinoRigheAll(CodiceUnita : string) {
    this._global.GETendpointCURRENT(); 
    let postData = {
      "PIVA": this._global.GETPIVA(),
      "CodiceUnita": this._global.GETCodiceUnita() 
  }
  console.log(postData)
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "BNC_MagazzinoRighe", postData, headers) 
  }


  loadBNCMovimentazioneCassa(Data:any) {
    this._global.GETendpointCURRENT();
    let postData = {
      "PIVA": this._global.GETPIVA(),
      "CodiceUnita": this._global.GETCodiceUnita(),
      "Data" : Data
  }
  console.log(postData)
    let headers = {headers:{'Accept':"application/json",'content-type':"application/json"}}
    return this.httpClient.post(this._global.myURL + this._global.GETLocal() + "BNC_MovimentazioneCassa", postData, headers) 
  }

  
}
