import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DocumentifeService } from '../services/documentife.service';
import { LoadingController, NavController } from '@ionic/angular';
import { GlobalService } from '../services/global.service';
import { ModalController } from '@ionic/angular';
import {  CalendarModal,  CalendarModalOptions,  DayConfig,  CalendarResult} from 'ion2-calendar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-documenti-xml',
  templateUrl: './documenti-xml.page.html',
  styleUrls: ['./documenti-xml.page.scss'],
})
export class DocumentiXmlPage implements OnInit {
  
  public IDGruppoSistema: string="INV";
  
  public myDownloadLink: string = "";

  constructor(public datepipe: DatePipe, public modalCtrl: ModalController, public httpClient: HttpClient, private router: Router, private _mysrv: DocumentifeService, private navCtrl: NavController, private _myglobal: GlobalService, public loadingController: LoadingController) {
    this._myglobal.GETUserData();
    if (!_myglobal.GetIDLicenzaAnagraficaSession())
    //if (this._myglobal.IDLicenzaAnagrafica==null || this._myglobal.IDLicenzaAnagrafica<=0)
    {
      this.navCtrl.navigateRoot('/login');
    }
  }
  public dataFine: string =   this.datepipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), 0), 'yyyy-MM-dd'); // new Date().toISOString();
  public dataInizio: string = this.datepipe.transform(new Date(new Date().getFullYear(), new Date().getMonth() -1, 1), 'yyyy-MM-dd'); 
 
  ngOnInit() {
  }

  async openCalendar(datetoupdate) {
    const loading = await this.loadingController.create({
      message: 'Caricamento...',
    });
    await loading.present();
    let defDate;
    if (datetoupdate=='start') {
      defDate =  this.dataInizio;
    }
    if (datetoupdate=='end') {
      defDate =  this.dataFine;
    }
    
    const options: CalendarModalOptions = {
      title: '',
      color:'primary',
      canBackwardsSelected : true,
      from : new Date(2017,1, 1),
      defaultDate : defDate,
      defaultScrollTo : defDate
    };

  let myCalendar =  await this.modalCtrl.create({
    component: CalendarModal,
    componentProps: { options }
  });

  myCalendar.present();

  loading.dismiss();

  const event: any = await myCalendar.onDidDismiss();
    const date: CalendarResult = event.data;
    if (datetoupdate=='start') {
      this.dataInizio =  this.datepipe.transform(date.dateObj, 'yyyy-MM-dd');
      console.log( this.dataInizio);
    }
    if (datetoupdate=='end') {
      this.dataFine = this.datepipe.transform(date.dateObj, 'yyyy-MM-dd');
      console.log( this.dataFine);
    }
    
}
  async downloadXML() {
    const loading = await this.loadingController.create({
      message: 'Download in corso...',
    });
    await loading.present();

    if (this.IDGruppoSistema=="INV") {
    this._mysrv.Download_DocumentiINVIATI(this.dataInizio, this.dataFine).subscribe((b64 : any) => {
      console.log(b64);
      this._mysrv.StoreFile('ALL_INV.zip', b64, false).subscribe((data : any) => {
        console.log(data);
        this.myDownloadLink = data[0].Result;  
        loading.dismiss();  
        
        this._mysrv.ListDocumentiINVIATIbyData(this.dataInizio, this.dataFine).subscribe((data)=>{
         let listDoc: any = [];
         listDoc = data;
          listDoc.forEach(element => {
            if (element.NomeDoc) {
            this._mysrv.InsertFEDownloaded(element.NomeDoc).subscribe((data : any) => { 
              });
            }
          });
        });
        
        console.log(this.myDownloadLink);    
        var anchor = document.createElement('a');
        anchor.href = this.myDownloadLink;
        anchor.target = '_blank';
        anchor.download = 'ALL_INV.zip' ;
        anchor.click();
      }, error => {
        console.log(error);
        loading.dismiss();
      });
    }, error => {
      console.log(error);
      loading.dismiss();
    });
    }

    if (this.IDGruppoSistema=="RIC") {
      this._mysrv.Download_DocumentiRICEVUTI(this.dataInizio, this.dataFine).subscribe((b64 : any) => {
        console.log(b64);
        this._mysrv.StoreFile('ALL_RIC.zip', b64, false).subscribe((data : any) => {
          console.log(data);
          this.myDownloadLink = data[0].Result;  
          loading.dismiss();  
            
        this._mysrv.ListDocumentiRICEVUTIbyData(this.dataInizio, this.dataFine).subscribe((data)=>{
          let listDoc: any = [];
          listDoc = data;
           listDoc.forEach(element => {
             if (element.NomeDoc) {
             this._mysrv.InsertFEDownloaded(element.NomeDoc).subscribe((data : any) => { 
               });
             }
           });
         });

          console.log(this.myDownloadLink);    
          var anchor = document.createElement('a');
          anchor.href = this.myDownloadLink;
          anchor.target = '_blank';
          anchor.download = 'ALL_RIC.zip' ;
          anchor.click();
        }, error => {
          console.log(error);
          loading.dismiss();
        });
      }, error => {
        console.log(error);
        loading.dismiss();
      });
      }
  

  }


  back(){
    this.navCtrl.back();
  }

  

}
